/*.App {*/
/*  text-align: center;*/
/*}*/

/*.App-logo {*/
/*  !*height: 5vmin;*!*/
/*  max-width: 114px;*/
/*  width: 100%;*/
/*  !*pointer-events: none;*!*/
/*}*/

/*!*@media (prefers-reduced-motion: no-preference) {*/
/*  .App-logo {*/
/*    animation: App-logo-spin infinite 20s linear;*/
/*  }*/
/*}*!*/

/*.App-header {*/
/*  background-color: #282c34;*/
/*  min-height: 100vh;*/
/*  display: flex;*/
/*  flex-direction: column;*/
/*  align-items: center;*/
/*  justify-content: center;*/
/*  font-size: calc(10px + 2vmin);*/
/*  color: white;*/
/*}*/

/*.App-link {*/
/*  color: #61dafb;*/
/*}*/

/*@keyframes App-logo-spin {*/
/*  from {*/
/*    transform: rotate(0deg);*/
/*  }*/
/*  to {*/
/*    transform: rotate(360deg);*/
/*  }*/
/*}*/



/*!*FORM HEADER*!*/
/*  .form-header {*/
/*    position: relative;*/
/*    display: flex;*/
/*    flex-direction: row;*/
/*    justify-content: space-around;*/
/*  }*/
/*  .form-header-line {*/
/*    position: absolute;*/
/*    top: 50%;*/
/*    transform: translateY( -50% );*/
/*    width: 100%;*/
/*    height: 1px;*/
/*    z-index: 1;*/
/*    background-color: gray;*/
/*  }*/
/*  .form-header-icon {*/
/*    font-size: 30px;*/
/*    color: #6d6969;*/
/*  }*/
/*  .form-header-icon-wrapper {*/
/*    display: inline-block;*/
/*    background: #fff;*/
/*    text-align: center;*/
/*    width: 40px;*/
/*    border-radius: 50%;*/
/*    z-index: 3;*/
/*    position: relative;*/
/*  }*/
/*  .form-header-icon-wrapper.active > .form-header-icon {*/
/*    color: #005cbf;*/
/*  }*/
/*  .form-header .progress-bar , .form-header .progress {*/
/*    height: 10px;*/
/*    transition: all .5s;*/
/*  }*/
/*  .form-header .progress-bar {*/
/*    width: 0;*/
/*  }*/
/*  .loader {*/
/*    width: 40px;*/
/*    height: 40px;*/
/*    display: block;*/
/*    margin: auto;*/
/*  }*/
/*  .loader-hidden {*/
/*    width: 0;*/
/*  }*/
/*!*FORM HEADER*!*/


/*!*STEP 2*!*/

/*!*ADDRESSES*!*/

/*.address-inner-wrapper {*/
/*  height: 100%;*/
/*  border: 1px solid rgba( 0,0,0,0.5);*/
/*  margin: 1px;*/
/*  padding: 5px;*/
/*  cursor: pointer;*/
/*}*/
/*.address-inner-wrapper.selected {*/
/*  border: 2px solid rgba( 0,0,0,1);*/
/*  margin: 0;*/
/*}*/
/*.address-inner-wrapper.min-height {*/
/*  min-height: 130px;*/
/*}*/
/*.address-inner-wrapper.min-height-alt {*/
/*	min-height: 50px;*/
/*}*/

/*.address-wrapper p {*/
/*  margin-bottom: 5px;*/
/*  font-size: 14px;*/
/*  font-weight: 500;*/
/*}*/

/*.add-new-icon {*/
/*  font-size: 40px;*/
/*}*/

/*.add-new-icon-wrapper {*/
/*  margin-top: 50%;*/
/*  transform: translateY(-50%);*/
/*}*/
/*!*ADDRESSES*!*/

/*!*STEP 2*!*/

/*!*STEP 3*!*/

/*.image_thumb {*/
/*  width: 30%;*/
/*  max-width: 300px;*/
/*}*/

/*!*STEP 3*!*/
@media screen and (min-width: 705px) and (max-width:1347px) {
   .order-product__header .order-product__desc-img {
        display: block!important;
    height: 60px;
    width: 60px;
    position: absolute;
    }
}
.voc_dmoc_stepthree {
    display: block;
    font-size: 14px!important;
}
.voc_dmoc_stepthree_mobile {
    display: block;
    font-size: 13px!important;

}
.order-cart-result-bottom-custom {
    justify-content: center!important;
    padding-right: 0!important;
}
.bold {
    font-weight:700 ;
}
.checkbox-stepthree {
    max-width: initial;
    margin-bottom: 15px;
}
.radio-custom {
    margin-right: 9px!important;
}
.radio-custom .radio__label{
    margin-bottom: 0!important;
}
.radio-custom~ a{
    font-size: 16px;
}
.order-cart-title-creating-order {
    text-align: center;
    font-size: 30px;
    margin-top: 10px;
    margin-bottom: 50px;
}
.order-cart-result-bottom__cost-header {
  font-size: 15px;
}

@media (min-width: 1366px) {
  .order-product__body{
       display: none!important;
  }
.step3shown .order-product__desc-img{
     display: flex!important;
  width: 128px;
}
}
@media (max-width: 1366px) {
.step3shown .order-product__body{
     display: block!important;
}.order-product__desc-img{
     display: none!important;
}
}
  .loader {
    width: 40px;
    height: 40px;
    display: block;
    margin: 1%;
    margin-left: auto;
    margin-right: auto;
  }
  .loader-hidden {
    width: 0;
  }


  .hidden {
    display: none;
  }
  .shown {
    display: block;
  }


  .catalog-wrapper {
    display: flex;
     flex-wrap: wrap;
  }

@media screen and (max-width: 8700px) {
    .catalog-item {
        width: 20%;
        margin: 20px;
        height: 420px;
        overflow: hidden;
        object-fit: contain;

    }
}
@media screen and (max-width: 1700px) {

  .catalog-item {
    width: 40%;
    margin: 20px;
      height: 420px;
      overflow: hidden;
      object-fit: contain;

  }
}
@media screen and (max-width: 700px) {

  .catalog-item {
    width: 100%;
    margin: 20px;
      height: 420px;
      overflow: hidden;
      object-fit: contain;

  }
}
.user-story-header {
  margin-top: 20px;
  margin-bottom: 20px;
}
  .catalog-image {
    height: 300px;
    object-fit: contain;
  }
.catalog-text {
  margin-top: 20px;
  display: block;
    color:#707070;
}
.catalog-button {
    border-radius:10px;
    border: 2px solid #296ebb;
    color: #296ebb;
    padding: 15px 60px;
  margin-top: 20px;
    font-weight: bold;
    background-image: url("./assets/img/icons/pdf.png");
    background-repeat: no-repeat;
    background-size: 35px;
    background-position: 10px center;
    position: relative;
    top: 40px;
}

  .text-center {
    text-align: center;
  }
.modal-terms iframe {
  height: 100%;
  width: 100%;
}
.modal-terms .modal-contacts__phones,.modal-terms .modal-contacts__body {
  height:100%;
}
.modal-terms {
  max-width: 90%;
    width: 2000px;
    height: 98vh;
    max-height: 98%;
}
.modal-contacts__close,
.modal-contacts__close:active,
.modal-contacts__close:focus,
.modal-contacts__close:hover
{
  border: none;
  outline: none;
}


/*HOME*/
.products-submenu {
  display: block!important;
}
.products-submenu__link:hover {
  cursor: pointer;
}
.products-submenu__link.active {
  color:black;
  font-weight: 600;
}
/*HOME*/


/*FOOTER*/
.layout {
  overflow: initial;
  overflow-x: hidden;
}
.products-footer {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
}
/*FOOTER*/


/*MOBILE-HOME*/
.products-menu-mobile {
  display: block;
}
.products-submenu-mobile__link.active {
  font-weight: bold;
}
@media (max-width: 1365px) {
.products-block .products-block-tabs-content__pane-content {
  display: block;
}
}



.each-slide > div {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  height: 350px;
}

.each-slide span {
  padding: 20px;
  font-size: 20px;
  background: #efefef;
  text-align: center;
}


/*MOBILE-HOME*/

/*AUTH*/
.auth-form-register__btn-wrap {
  max-width: 434px;
}
@media (max-width: 700px) {
.auth-form-register__btn {
  margin-top: 5px;
  margin-bottom: 5px;
}
.login .auth-form-register__btn-wrap {
  justify-content: center;
}}
.auth-form-country__right img {
  width: 35px;
  height: 22px;
}
.auth-form-block__input[type=tel] {
  font-size: 20px;
  color: gray
}
/*AUTH*/

/*STEPS*/
.order-stages__block.active img {
    filter: brightness(5);
}
.order-stages__block img.dumb-icon {
    width: 44px;
    filter: brightness(0.5);
}
.input-block__field.is-invalid {
  border: 1px solid #dc3545;
  box-shadow: 0 0 0 0.2rem rgb(220 53 69 / 25%);
}
.order-action__comment {
  padding: 15px;
  font-size: 18px;
}
.disabled_input {
    background: transparent;
    border: none;
}
@media (max-width: 1366px) {
.order-delivery__button-wrap,.order-cart-result-center * {
    text-align: center!important;
}
    .order-cart-result-bottom__price-delivery ,
    .order-cart-result-top__cost,
    .order-cart-result-center__cost ,
    .order-cart-result-center__cost span,
    .order-cart-result-bottom__price-delivery  span,
    .order-cart-result-top__cost span {
       font-size: 22px!important;
    }
    .order-cart-result-center {
        padding: 0;
    }
}
@media (max-width: 500px) {


.order-cart-result-bottom__delivery {
    width: 100%;
}
    .order-info-section__header,
    .center-xs,
    label.order-action__label {
        text-align: center;
    }
    .order-cart-result-bottom__delivery-caption,
    .order-cart-result-bottom__delivery-right,
    .order-cart-result-bottom__price-delivery,
    .order-cart-result-center,
    .order-cart-result-center__cost,
    .order-cart-result__cost,
    .order-info-blocks,
    .order-info-block,
    .order-delivery__radios-wrap
    {
    text-align: center!important;
        margin-right: 0;
    }
    .order-cart-result-bottom__price-delivery ,
    .order-cart-result-top__cost,
    .order-cart-result-center__cost ,
    .order-cart-result-center__cost span,
    .order-cart-result-bottom__price-delivery  span,
    .order-cart-result-top__cost span {
       font-size: 16px!important;
    }
    .order-cart-result-center {
        padding: 0;
    }
}
/*STEPS*/


/*ORDERS*/
.user-order__id {
    flex-basis: 35%;
    max-width: 100%;
    text-align: center;
}
.user-order__date {
    flex-basis: 30%;
    max-width: 100%;
    text-align: center;
}
.user-order__name {
    flex-basis: 20%;
    max-width: 100%;
    text-align: center;
}
.user-order__state {
    flex-basis: 20%;
    max-width: 100%;
    text-align: center;
}
.user-order__more {
    max-width: 100%;
    flex-basis: 10%;
    text-align: center;
}
.user-orders-header__id {
    flex-basis: 35%;
    max-width: 100%;
    text-align: center;

}
.user-orders-header__date {
    flex-basis: 30%;
    max-width: 100%;
    text-align: center;

}
.user-orders-header__name {
    flex-basis: 20%;
    max-width: 100%;
    text-align: center;

}
.user-orders-header__state {
    flex-basis: 20%;
    max-width: 100%;
    text-align: center;
}
.user-orders-header__more {
    flex-basis: 10%;
    max-width: 100%;
    text-align: center;
}

@media (max-width: 1366px) {
    .user-order__id {
        flex-basis: 15%;
        font-size:15px;
        text-align: center;
        width: 100%;
        flex: 1;
        flex-basis: 100%;
    }
}
@media (min-width: 200px) and (max-width: 500px) {
    .user-order__id,
    .user-order__name,
    .user-order__date,
    .user-order__state,
    .user-order__more {
        flex-basis: 15%;
        font-size:15px;
        text-align: center;
        width: 100%;
        flex: 1;
        flex-basis: 100%;
    }
    .user-order__name {
        margin-bottom: 0;
    }
    .user-order__state {
        margin-bottom: 20px;
    }
}
/*ORDERS*/

/*COMMON*/
.my-4 {
  margin-top: 15px;
  margin-bottom: 15px;
}
.profileAddressesDropshippingLabel {
  font-weight: bold;
  font-size: 15px;
  margin-right: 7px;
}
.profileAddressesDropshippingWrapper {
    display: flex;
    align-items: center;
}
.text-gray {
    color:#707070;
}
.contacts-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    align-items: center;
}
.contact-wrapper {
    margin:15px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.contact-img {
    height: 120px;
    border-radius: 50%;
}
.contact-div {
    margin-left: 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
.contact-div p,
.contact-div a {
    margin: 5px;
    font-size: 17px;
    align-self: flex-start;
}
.contact-name,
.contact-phone {
    font-weight: bold;
}
.contact-position {
    color: #6a6a6a;
}
@media screen and (max-width: 400px) {
    .contacts-wrapper {
        flex-direction: column!important;
        justify-content: center!important;
        align-items: center!important;
    }
    .contact-div p,
    .contact-div a {
        font-size: 15px;
    }
    .contact-img {
        height: 70px;
    }
.contact-div {
    margin-left: 10px;
}
}
.mt-6 {
  margin-top: 4rem!important;
}
.my-5 {
  margin-top: 25px;
  margin-bottom: 25px;
}


.pointer {
  cursor: pointer;
}
.contacts ,.basket{
  right:-28px;
}

.btn-danger {
  border: 1px solid #dc3545;
    background-color: #dc3545;
    transition: all 0.25s;
}
.text-center {
  text-align: center!important;
}
.btn-block {
  display: block;
  width: 100%;
}
.btn-danger:hover {
    color: #dc3545;
    background-color: #ffffff;
}
.products-block__price,
.products-block__price span {
  font-size: 17px!important;
}
.user-story-order {
  margin-bottom: 3rem;
}

 .message-alert__descr {
  font-size: 20px;
     line-height: 22px!important;
}
.message-alert-success .message-alert__descr {
  font-size: 30px;
    line-height: 35px!important;
}
.message-alert__close {
      width: 17px;
    height: 17px;
    background-size: contain;
}
.mt-2 {
    margin-top:2rem;
}
.mt-1 {
    margin-top:1rem;
}
.modal-mask {
    position: fixed;
    top: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    left: 0;
    right: 0;
    background: rgba(0,0,0,0.6);
    max-width: 100%;
    z-index: 1500;
}
.message-alert {
  z-index: 1800;
}
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;700&display=swap');
@font-face { font-family: Josefin Sans;
			 src: url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;700&display=swap'); }


body , * {
	font-family: 'Montserrat', sans-serif;
}

.header .navigation__link {
    font-size: 14px;
}
.auth-form-register__btn-wrap {
    max-width: 481px;
}
.select_stat {
    min-width: 100%;
    width: 100%;
    border: 1px solid transparent;
    border-radius: 13px;
}
.select_stat.is-invalid {
    border: 1px solid #dc3545;
    box-shadow: 0 0 0 0.2rem rgb(220 53 69 / 25%);
    border-radius: 13px;
}
.select_stat .select__curr{
    width: 100%;
    padding: 7px 20px;
    border-radius: 10px;
    font-size: 15px;
    line-height: 37px;
    border: 1px solid #bebebe;
    box-shadow: none;
}

.product-availability-info-container {
    font-size: 12px;
    margin-bottom: 10px;
}
.product-price-info-container {
    font-size: 12px;
    margin-top: 10px;
}
button.disabled {
    background-color: #ededed;
    color: #0908086e;
    border-color: #c2c2c2;;
}
.order-address-button{
    margin: auto;
    display: block;
    padding-left: 68px;
    padding-right: 68px;
}
/*COMMON*/


/*GALLERY*/
.gallery-mask {
    display: none;
    position: fixed;
    top: 0;
    bottom: 0;
    width: 100%;
    left: 0;
    right: 0;
    background: rgba(0,0,0,0.4);
    z-index: 9999;
}
.gallery-close-icon {
    cursor: pointer;
    position: fixed;
    top: 0;
    right: 0;
    margin-right: 5%;
    margin-top: 4%;
    height: 40px;
}
.gallery-prev-icon,
.gallery-next-icon {
    cursor: pointer;
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    margin-right: 3%;
    height: 50px;
}
.gallery-prev-icon {
    left: 0;
    margin-left: 3%;
}
.gallery-img-wrapper {
    width: 100%;
    display: flex;
    height: 100%;
    align-self: center;
    padding-top: 100px;
    padding-bottom: 100px;
    /*background-image: url(https://dealer.dashandel.cz/images/a72deb5d-a16e-4f57-b9fc-ce3a7d261663.jpg);*/
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    /*margin-left: 5%;*/
    /*margin-right: 5%;*/
    justify-content: center;
    align-items: center;
        overflow: hidden;
    /*margin-top: 10%;*/
    /*margin-bottom: 10%;*/
}
.gallery-img-wrapper img {
    width: 79%;
    height: 80%;
    position: fixed;
    object-fit: contain;
    transform: translateY(5800px);
    -webkit-transition:  all .2s ease;
    -moz-transition:  all .2s ease;
    transition: all .2s ease;
}
.gallery-img-wrapper img.active {
    -webkit-transition:  all .2s ease;
    -moz-transition:  all .2s ease;
    transition:all .2s;
    transform: translateY(0px);
}
@media screen and (max-width: 500px) {

.gallery-prev-icon,
.gallery-next-icon {
    height: 30px;
    margin-right: 2%;
}
.gallery-prev-icon {
    margin-left: 2%;
}
.gallery-close-icon {
    height: 25px;
}
}
.products-block-card__img img,
.products-block-card__img picture,
[data-gallery] {
    cursor: pointer;
}
/*GALLERY*/
