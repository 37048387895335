@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*!
 * Bootstrap Reboot v4.5.2 (https://getbootstrap.com/)
 * Copyright 2011-2020 The Bootstrap Authors
 * Copyright 2011-2020 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 * Forked from Normalize.css, licensed MIT (https://github.com/necolas/normalize.css/blob/master/LICENSE.md)
 */
*,
*::before,
*::after {
  box-sizing: border-box;
}
html {
  font-size: 14px;
  font-family: sans-serif;
  line-height: 1;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}
body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1;
  color: #010101;
  text-align: left;
  background-color: #fff;
}
[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}
hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0;
}
p {
  margin-top: 0;
  margin-bottom: 0;
}
abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
}
address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}
ol,
ul,
dl {
  margin: 0;
  padding: 0;
  list-style: none;
  margin-top: 0;
  margin-bottom: 0;
}
ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}
dt {
  font-weight: 700;
}
dd {
  margin-bottom: .5rem;
  margin-left: 0;
}
blockquote {
  margin: 0 0 1rem;
}
b,
strong {
  font-weight: 700;
}
small {
  font-size: 80%;
}
sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: #0056b3;
  text-decoration: none;
}
a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}
pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}
pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}
figure {
  margin: 0 0 1rem;
}
img {
  vertical-align: middle;
  border-style: none;
  max-width: 100%;
}
svg {
  overflow: hidden;
  vertical-align: middle;
}
table {
  border-collapse: collapse;
}
caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}
th {
  text-align: inherit;
}
label {
  display: inline-block;
  margin-bottom: 0;
}
button {
  border-radius: 0;
}
button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}
input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  outline: none;
}
button,
input {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
[role="button"] {
  cursor: pointer;
}
select {
  word-wrap: normal;
}
button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}
input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}
textarea {
  overflow: auto;
  resize: vertical;
}
fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}
legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}
progress {
  vertical-align: baseline;
}
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}
output {
  display: inline-block;
}
summary {
  display: list-item;
  cursor: pointer;
}
template {
  display: none;
}
[hidden] {
  display: none !important;
}
.container {
  width: 100%;
  padding-right: 20px;
  padding-left: 20px;
  margin-right: auto;
  margin-left: auto;
}
.px-0 {
  padding-left: 0!important;
  padding-right: 0!important;
}
@media (min-width: 320px) {
  .container {
    max-width: 320px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 768px;
    padding-right: 12px;
    padding-left: 12px;
  }
}
@media (min-width: 1366px) {
  .container {
    max-width: 1366px;
    padding-right: 20px;
    padding-left: 20px;
  }
}
.layout {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.main {
  flex-grow: 1;
}
.title {
  text-align: center;
  color: #457800;
  font-size: 44px;
  font-weight: 700;
  text-transform: uppercase;
}
.title_v2 {
  text-align: center;
  color: #000000;
  font-size: 36px;
  font-weight: 700;
  text-transform: uppercase;
}
.subtitle_v2 {
  text-align: center;
  color: #000000;
  font-size: 29px;
  font-weight: 700;
}
.title-solid-block {
  text-align: center;
  padding-top: 18px;
  padding-bottom: 22px;
  background-color: #457800;
}
.title-solid {
  display: inline-block;
  color: #ffffff;
  font-size: 36px;
  font-weight: 700;
  text-transform: uppercase;
}
.title-solid::before,
.title-solid::after {
  content: '';
  display: inline-block;
  vertical-align: middle;
  margin-top: -2px;
  width: 99px;
  height: 1px;
  background-color: #ffffff;
  opacity: 0.32;
}
.title-solid::before {
  margin-right: 36px;
}
.title-solid::after {
  margin-left: 36px;
}
.text {
  font-size: 25px;
  font-weight: 700;
  line-height: 32px;
}
.text-section {
  color: #000000;
  font-size: 18px;
  font-weight: 300;
  line-height: 30px;
}
.button {
  display: inline-flex;
  border: none;
  border-radius: 5px;
  outline: none;
  background-color: transparent;
}
.button--o {
  color: #006634;
  border: 1px solid #006634;
  background-color: #ffffff;
  transition: all 0.25s;
}
.button--o:hover {
  color: #ffffff;
  box-shadow: inset 0 0 0 2em #006634;
}
.button--solid {
  border: 1px solid #006634;
  background-color: #006634;
  color: #ffffff;
  transition: all 0.25s;
}
.button--solid:hover {
  color: #006634;
  background-color: #ffffff;
}
.btn-hamburger-wrap {
  display: none;
  padding: 10px 12px 14px 12px;
  border-right: 1px solid #5393d9;
  background-color: #006634;
}
.btn-hamburger-wrap.opened .btn-hamburger {
  transform: rotate(90deg);
}
.btn-hamburger {
  position: relative;
  z-index: 999;
  cursor: pointer;
  width: 22px;
  height: 19px;
  transition: all 300ms ease-in-out;
}
.btn-hamburger span {
  position: absolute;
  top: 0;
  display: block;
  width: 22px;
  height: 3px;
  background-color: #ffffff;
  transition: all 0.5s;
}
.btn-hamburger span:nth-child(3) {
  z-index: 4;
  top: 16px;
}
.btn-hamburger span:nth-child(1) {
  z-index: 4;
}
.btn-hamburger span:nth-child(2) {
  z-index: 3;
  top: 8px;
}
.select {
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: pointer;
}
.select__caption {
  margin-bottom: 10px;
  color: #707070;
  font-size: 17px;
  font-weight: 400;
}
.select__container {
  position: relative;
}
.select__curr {
  min-height: 55px;
  position: relative;
  display: block;
  color: #707070;
  font-size: 17px;
  padding: 16px 40px 18px 16px;
  font-weight: 400;
  line-height: 21.73px;
  box-shadow: 0 0 6px rgba(1, 1, 1, 0.23);
  border-radius: 10px;
}
.select__curr.opened {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.select__curr.opened::after {
  transform: translateY(-50%) rotate(180deg);
}
.select__curr::after {
  content: '';
  position: absolute;
  top: 50%;
  transform: translateY(-50%) rotate(0);
  transition: transform .3s;
  right: 15px;
  display: inline-block;
  width: 16px;
  height: 16px;
  background: #ffffff url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAJCAYAAAA7KqwyAAAAzElEQVQokY3RPUoDURTF8d9MBgvBNSl+hKSSgApp7e1cgAsQhrSWqUTICiwsXIKNIkGLFIHBVtBGC48yjKIeeNzHuf/D5b1b1HW9gWMc4Nn/tIoLnFY4wTpm2MXrH+GVsAOsldjHTYxzVL+EqzCDZPZKNOjjHiNM0fsh3EtvFLaPpkxziU08YIwzFK1wEW+Mx7BLKFvQAjuph5i0epN4C2ynfr2prXmAaxzhBW+5Nxkw735KV3fYwpWP9cJTvNsuXHaN6HMrlznDeN/0DubALb4t5ARXAAAAAElFTkSuQmCC) center no-repeat;
}
.select__list {
  display: none;
  position: absolute;
  z-index: 10;
  top: 100%;
  left: 0;
  width: 100%;
  background: #fff;
  box-shadow: 0 3px 6px 0px rgba(1, 1, 1, 0.23);
  border-radius: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.select__item {
  position: relative;
  display: block;
  color: #707070;
  font-size: 17px;
  padding: 16px 16px 18px 16px;
  font-weight: 400;
  line-height: 21.73px;
}
.select__item:nth-child(even) {
  background-color: #e7e7e7;
}
.select__item:nth-child(1)::before {
  content: '';
  position: absolute;
  top: 0;
  left: 4px;
  width: calc(100% - 8px);
  height: 1px;
  background-color: #e7e7e7;
}
.radio__label {
  margin-bottom: 8px;
  position: relative;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}
.radio__input {
  position: absolute;
  left: 0;
  opacity: 0;
  z-index: -1;
}
.radio__input + .radio__check {
  border: none;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.35);
  background-color: #ffffff;
  display: flex;
  flex-shrink: 0;
  position: relative;
  cursor: pointer;
  width: 22px;
  height: 22px;
  transition: background 0.15s ease-out;
  border-radius: 50%;
}
.radio__input + .radio__check::before {
  content: "";
  display: flex;
  position: absolute;
  left: 50%;
  margin-left: -14px;
  bottom: 50%;
  margin-bottom: -14px;
  width: 28px;
  height: 28px;
  transition: transform 0.15s ease-out;
  border-radius: 50%;
  transform: scale(0);
  background-color: #ffffff;
}
.radio__input:checked + .radio__check::before {
  transform: scale(0.5);
  background: #006634;
  background-size: 21px;
}
.radio__check {
  margin-right: 13px;
}
.radio__title {
  color: #000000;
  font-size: 17px;
  font-weight: 700;
}
.radio__descr {
  padding-left: 35px;
  color: #707070;
  font-size: 15px;
  font-weight: 400;
  line-height: 21.73px;
}
.input-block__field {
  width: 100%;
  padding: 7px 20px;
  border-radius: 10px;
  font-size: 15px;
  line-height: 37px;
  border: 1px solid #bebebe;
}
.input-block__field::-webkit-input-placeholder {
  color: #7a7a7a;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 37px;
}
.input-block__field:-ms-input-placeholder {
  color: #7a7a7a;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 37px;
}
.input-block__field::placeholder {
  color: #7a7a7a;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 37px;
}
.input-block__field:focus {
  border: 1px solid #006634;
}
.header {
  padding-top: 22px;
  padding-bottom: 24px;
  border-top: 6px solid #006634;
  box-shadow: 0px 23px 32px -28px rgba(0, 0, 0, 0.27);
}
.header .container {
  position: relative;
  display: flex;
  align-items: center;
}
.header .logo {
  margin-right: 39px;
}
.header-link-conditions {
  margin-right: 84px;
  font-size: 15px;
  font-weight: 400;
  padding: 12px 27px 13px 22px;
}
.navigation__list {
  display: flex;
}
.navigation__list > *:not(:last-child) {
  margin-right: 29px;
}
.navigation__link {
  position: relative;
  color: #707070;
  font-size: 17px;
  font-weight: 400;
  transition: all 0.3s;
}
.navigation__link.active {
  font-weight: 700;
}
.navigation__link::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: -4px;
  width: 100%;
  height: 1px;
  background: #006634;
  transform: scaleX(0);
  transition: transform .3s ease;
}
.navigation__link:hover {
  color: #006634;
}
.navigation__link:hover::before {
  transform: scaleX(1);
}
.contacts {
  position: absolute;
  z-index: 200;
  top: -22px;
  right: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 55px;
  width: 59px;
  background-color: #006634;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  cursor: pointer;
  transition: background-color 0.2s;
}
.contacts__icon svg {
  width: 23px;
  height: 23px;
  fill: #fff;
}
.contacts:hover {
  background-color: #004523;
}
.basket {
  position: absolute;
  z-index: 4;
  top: 36px;
  right: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 59px;
  height: 57px;
  border-radius: 10px;
  background-color: #363636;
  transition: background-color 0.2s;
}
.basket__icon svg {
  width: 34px;
  height: 28px;
  fill: #fff;
}
.basket:hover {
  background-color: #434343;
}
.basket__count {
  position: absolute;
  top: 5px;
  left: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 4px;
  height: 18px;
  border-radius: 50%;
  font-size: 17px;
  font-weight: 700;
  color: #000000;
  background-color: #f0f1ec;
}
.products {
  padding-top: 39px;
}
.products-main-container {
  margin-bottom: 192px;
}
.products-menu-mobile-wrap {
  display: none;
}
.products-header {
  display: flex;
  align-items: center;
}
.products-header__left {
  margin-top: -14px;
  flex-basis: 339px;
  flex-shrink: 0;
}
.products-header__right {
  display: flex;
  align-items: center;
  flex-grow: 1;
}
.products-header__title {
  color: #707070;
  font-size: 24px;
  font-weight: 700;
  line-height: 21.73px;
}
.products-header__link {
  display: block;
  padding-bottom: 3px;
  color: #006634;
  font-size: 15px;
  border-bottom: 2px dotted #006634;
}
.products-header__link:hover {
  border-bottom: 2px dotted transparent;
}
.products-header__view-all-products {
  margin-right: 27px;
}
.products-header__view-order-products {
  margin-right: 49px;
}
.products-body {
  display: flex;
}
.products-body__left {
  flex-basis: 339px;
  flex-shrink: 0;
}
.products-body__right {
  flex-grow: 1;
}
.products-footer {
  margin-bottom: 34px;
}
.products-footer .container {
  display: flex;
}
.products-footer--fixed .container {
  position: fixed;
  z-index: 1000;
  bottom: 0;
  left: 0;
  right: 0;
}
.products-footer__left,
.products-footer__right {
  position: relative;
  display: flex;
  align-items: center;
  padding-top: 17px;
  padding-bottom: 20px;
}
.products-footer__left {
  max-width: 561px;
  flex-shrink: 0;
  width: 100%;
  background-color: #e4e4e4;
}
.products-footer__left::before {
  content: '';
  position: absolute;
  top: 0;
  right: 100%;
  display: block;
  height: 100%;
  width: 100vw;
  background-color: #e4e4e4;
}
.products-footer__right {
  padding-left: 36px;
  flex-grow: 1;
  background-color: #f0f0f0;
}
.products-footer__right::before {
  content: '';
  position: absolute;
  top: 0;
  left: 100%;
  display: block;
  height: 100%;
  width: 100vw;
  background-color: #f0f0f0;
}
.products-footer__delivery {
  color: #202020;
  font-size: 14px;
  font-weight: 700;
  line-height: 21.73px;
}
.products-footer-order {
  display: flex;
  align-items: center;
}
.products-footer-order__icon {
  margin-right: 24px;
}
.products-footer-order__info {
  margin-right: 37px;
  color: #101010;
  font-size: 15px;
  font-weight: 300;
  line-height: 21.73px;
}
.products-footer-order__info span {
  font-weight: 700;
}
.products-footer-order__btn {
  padding: 9px 20px 11px 20px;
  color: #101010;
  font-size: 15px;
  font-weight: 300;
  border: none;
  border-radius: 5px;
  background-color: #ffffff;
  transition: all 0.25s;
}
.products-footer-order__btn:hover {
  color: #ffffff;
  box-shadow: inset 0 0 0 2em #006634;
}
.products-menu-wrap__header {
  display: none;
}
.products-menu__header {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.products-menu__icons {
  position: relative;
  width: 23px;
  margin-right: 16px;
  height: 16px;
  border-right: 1px solid #dcdcdc;
}
.products-menu__icon {
  position: absolute;
  left: 0;
  right: 0;
  top: -2px;
  bottom: 0;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  color: #707070;
  font-size: 22px;
  font-weight: 500;
}
.products-menu__icon.minus {
  left: 2px;
}
.products-menu__title {
  color: #000000;
  font-size: 17px;
  font-weight: 500;
  line-height: 37px;
}
.products-menu__item.closed .products-menu__icon.plus {
  display: block;
}
.products-menu__item.closed .products-menu__icon.minus {
  display: none;
}
.products-menu__item.opened .products-menu__title {
  font-weight: 700;
}
.products-menu__item.opened .products-menu__icon.plus {
  display: none;
}
.products-menu__item.opened .products-menu__icon.minus {
  display: block;
}
.products-submenu {
  display: none;
  padding-left: 39px;
  padding-bottom: 23px;
}
.products-submenu__link {
  position: relative;
  color: #737373;
  font-size: 17px;
  line-height: 37px;
  transition: all 0.3s;
}
.products-submenu__link::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: -4px;
  width: 100%;
  height: 1px;
  background: #006634;
  transform: scaleX(0);
  transition: transform .3s ease;
}
.products-submenu__link:hover {
  color: #006634;
}
.products-submenu__link:hover::before {
  transform: scaleX(1);
}
.products-blocks {
  padding-top: 17px;
}
.products-block {
  margin-bottom: 8px;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}
.products-block-header {
  padding: 13px 19px 13px 13px;
  display: flex;
  align-items: center;
  grid-gap:10px;
  gap:10px;
}
.products-block-footer {
  display: none;
}
.products-block__icon {
  flex: 0 0 62px;
  max-width: 62px;
  width: 100%;
}
.products-block__title {
  flex: 0 0 276px;
  max-width: 276px;
  width: 100%;
  color: #000000;
  font-size: 17px;
  line-height: 21.73px;
  font-weight: 700;
}
.products-block__more {
  flex: 0 0 145px;
  max-width: 145px;
  width: 100%;
  text-align: right;
  padding-right: 9px;
  color: #006634;
  font-size: 15px;
  font-weight: 400;
  cursor: pointer;
}
.products-block__more:hover {
  opacity: 0.8;
}
.products-block__more::after {
  content: '';
  display: inline-block;
  margin: 0 6px;
  width: 12px;
  height: 6px;
  transform: rotate(0);
  vertical-align: middle;
  background: #ffffff url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAGBAMAAAAFwGKyAAAAAXNSR0IB2cksfwAAABtQTFRFAAAAO26vOm2vQHCvO26vOm6vO2+vO22vOm6vUTFp8gAAAAl0Uk5TAKBgENDAn7+Qy+x98gAAAClJREFUeJxjEGIAAkUGEwUGBiZnBuYwBgZRAwYGxgRGAZCEeAWIZABxADdgAn2M7yCFAAAAAElFTkSuQmCC) center no-repeat;
  transition: transform .3s;
}
.products-block.opened .products-block__more::after {
  transform: rotate(180deg);
}
.products-block__price {
  text-align: right;
  flex: 0 0 156px;
  max-width: 156px;
  width: 100%;
  color: #010101;
  font-size: 17px;
  line-height: 21.73px;
  font-weight: 700;
}
.products-block__price span {
  font-size: 20px;
}
.products-block__status {
  margin-bottom: -1px;
  display: none;
  color: #006634;
  font-size: 15px;
  font-weight: 400;
}
.products-block__count {
  display: flex;
  align-items: center;
}
.products-block__count-minus,
.products-block__count-plus {
  width: 14px;
  height: 14px;
  cursor: pointer;
}
.products-block__count-minus {
  margin-right: 8px;
  background: #ffffff url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAEAQMAAACA6EzJAAAAAXNSR0IB2cksfwAAAAZQTFRFAAAAcHBwjqzZVQAAAAJ0Uk5TAP9bkSK1AAAADUlEQVR4nGP4/4EBhgAukge9/dOKwQAAAABJRU5ErkJggg==) center no-repeat;
}
.products-block__count-plus {
  background: #ffffff url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOBAMAAADtZjDiAAAAAXNSR0IB2cksfwAAABhQTFRFAAAAcXFxcXFxcnJycXFxcXFxcHBwc3NzeW/WSwAAAAh0Uk5TAP9Ed7uZZjNOLIvnAAAAKUlEQVR4nGNgYGAUFGIAAfy0oSAIiGDQysaGgqLGZgT1MzCwuLgzMAAA7PgEuOPfz9cAAAAASUVORK5CYII=) center no-repeat;
}
.products-block__count-input {
  padding: 4px;
  margin-right: 7px;
  max-width: 47px;
  width: 100%;
  color: #000000;
  font-size: 19px;
  font-weight: 700;
  text-align: center;
  border-radius: 5px;
  border: 1px solid #c2d5ec;
}
.products-block__count-input::-webkit-input-placeholder {
  color: #000000;
  font-size: 19px;
  font-weight: 700;
  font-family: 'Roboto', sans-serif;
}
.products-block__count-input:-ms-input-placeholder {
  color: #000000;
  font-size: 19px;
  font-weight: 700;
  font-family: 'Roboto', sans-serif;
}
.products-block__count-input::placeholder {
  color: #000000;
  font-size: 19px;
  font-weight: 700;
  font-family: 'Roboto', sans-serif;
}
.products-block__cost {
  color: #010101;
  font-size: 17px;
  line-height: 21.73px;
  font-weight: 700;
}
.products-block__cost-price-cur {
  display: inline;
  white-space: nowrap;
}
.products-block__cost span {
  font-size: 20px;
}
.products-block__add {
  display: none;
  width: 42px;
  height: 42px;
  border: none;
  border-radius: 50%;
  background-color: #006634;
}
.products-block--mod-1 .products-block-header .products-block__count {
  flex: 0 0 188px;
  max-width: 188px;
  width: 100%;
  justify-content: flex-end;
  padding-right: 15px;
}
.products-block--mod-1 .products-block__cost {
  text-align: right;
  flex-grow: 1;
}
.products-block--mod-2 .products-block-header .products-block__count {
  flex-grow: 1;
  order: 1;
  justify-content: flex-end;
}
.products-block--mod-2 .products-block__cost {
  display: none;
  text-align: right;
  flex: 0 0 184px;
  max-width: 184px;
  width: 100%;
  padding-left: 10px;
}
.products-block--mod-2.selected .products-block__cost {
  display: block;
}
.products-block--mod-3 .products-block-header .products-block__count {
  flex: 0 0 188px;
  max-width: 188px;
  width: 100%;
  justify-content: flex-end;
  padding-right: 15px;
}
.products-block--mod-3 .products-block__cost {
  display: none;
}
.products-block--mod-3 .products-block__add {
  display: flex;
  justify-content: center;
  text-align: right;
  align-items: center;
  margin-left: auto;
}
.products-block--ordered .products-block__status {
  display: block;
  flex: 0 0 107px;
  max-width: 107px;
  width: 100%;
  text-align: right;
  padding-right: 13px;
}
.products-block--ordered .products-block-header .products-block__count-minus,
.products-block--ordered .products-block-header .products-block__count-plus {
  display: none;
}
.products-block--ordered .products-block-header .products-block__count-input {
  margin-right: 0;
}
.products-block--ordered .products-block-header .products-block__count {
  flex: 0 0 62px;
  max-width: 62px;
  width: 100%;
  justify-content: flex-end;
  padding-right: 15px;
}
.products-block--ordered .products-block__cost {
  text-align: right;
  flex-grow: 1;
}
.products-block-body {
  display: none;
  padding: 8px 38px 0px 38px;
}
.products-block .products-block-tabs {
  display: flex;
}
.products-block .products-block-tabs > *:not(:last-child) {
  margin-right: 30px;
}
.products-block .products-block-tabs__item {
  color: #006634;
  font-size: 15px;
  font-weight: 700;
  cursor: pointer;
}
.products-block .products-block-tabs__item:hover {
  opacity: 0.8;
}
.products-block .products-block-tabs__item.active {
  color: #000000;
}
.products-block .products-block-tabs-content__pane {
  display: none;
}
.products-block .products-block-tabs-content__pane-tab-mobile {
  display: none;
}
.products-block .products-block-tabs-content__pane.active {
  display: block;
}
.products-block-card {
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.products-block-card__title {
  margin-bottom: 11px;
  min-height: 32px;
  color: #4b4b4b;
  font-size: 15px;
  line-height: 16.73px;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
    text-align: center;
}
.products-block-card__title span {
 font-weight: bold;
    text-align: center;
    width: 100%;
    margin-top: 5px;
    font-size: 12px;
}
.products-block-card__img {
  margin-bottom: 18px;
}
.products-block-card__count {
  justify-content: center;
}
.products-block-color {
  padding-top: 23px;
}
.products-block-chars {
  padding-top: 19px;
  padding-bottom: 55px;
  display: flex;
  flex-wrap: wrap;
}
.products-block-chars__item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 58px 8px 18px;
  margin-right: 29px;
  max-width: 401px;
  width: 100%;
  color: #000000;
  font-size: 14px;
  font-weight: 300;
  line-height: 21.73px;
}
.products-block-chars__item:nth-child(4n+1),
.products-block-chars__item:nth-child(4n+2) {
  background-color: #f3f3f3;
}
.products-block-chars__item:nth-child(4n+3),
.products-block-chars__item:nth-child(4n+4) {
  padding: 11px 58px 11px 18px;
  background-color: #ffffff;
}
.products-block-chars__key {
  padding-right: 25px;
}
.products-block-chars__value {
  text-align: right;
}
.products-block-descr {
  padding-top: 28px;
  padding-bottom: 48px;
}
.products-block-descr > *:not(:last-child) {
  margin-bottom: 23px;
}
.products-block-descr__text {
  color: #000000;
  font-size: 14px;
  font-weight: 300;
  line-height: 21.73px;
}
.search {
  position: relative;
  flex-grow: 1;
}
.search__input {
  padding: 7px 16px;
  border-radius: 10px;
  width: 100%;
  font-size: 15px;
  line-height: 37px;
  border: 1px solid #bebebe;
}
.search__input::-webkit-input-placeholder {
  color: #7a7a7a;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-style: italic;
  font-size: 15px;
  line-height: 37px;
}
.search__input:-ms-input-placeholder {
  color: #7a7a7a;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-style: italic;
  font-size: 15px;
  line-height: 37px;
}
.search__input::placeholder {
  color: #7a7a7a;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-style: italic;
  font-size: 15px;
  line-height: 37px;
}
.search__input:focus {
  border: 1px solid #006634;
}
.search__icon {
  position: absolute;
  top: 13px;
  right: 19px;
  cursor: pointer;
}
.view-all-colors__wrap {
  display: none;
}
.order {
  padding-top: 76px;
}
.order-top .container {
  display: flex;
  align-items: center;
}
.order-back-link {
  position: relative;
  display: block;
  padding: 14px 35px 14px 42px;
  border-radius: 5px;
  border: 1px solid #d0dff0;
  background-color: #ffffff;
  color: #006634;
  font-size: 17px;
  font-weight: 500;
  transition: all 0.25s;
}
.order-back-link__wrap {
  width: 112px;
  margin-right: 142px;
}
.order-back-link:hover {
  color: #ffffff;
  box-shadow: inset 0 0 0 2em #006634;
  border: 1px solid #006634;
}
.order-back-link::before {
  content: '';
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 15px;
  display: inline-block;
  width: 9px;
  height: 16px;
  background: transparent url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAQBAMAAAA2ZkhwAAAAAXNSR0IB2cksfwAAACRQTFRFAAAAgJ+/fpaxgJW1fJWwfJWxfZWvfJavfJawfpSyfZWwfZaxeFc63QAAAAx0Uk5TAAhfGGd/YFB3T3howx1F8gAAAEJJREFUeJxjYGBgEDIAEoyuCUBSJEyAgYHZtQDItAgHEsytcCZDagGYnAAirSJBJPNSZM5WMEc6SABk2laQaQxiBgABywt6Rs1q+wAAAABJRU5ErkJggg==) center no-repeat;
}
.order-stages {
  position: relative;
  max-width: 815px;
  width: 100%;
  border-radius: 10px;
  border: 1px solid #e3e3e3;
  background-color: #ffffff;
}
.order-stages__header {
  margin-top: -23px;
  text-align: center;
  color: #006634;
  font-weight: 700;
  font-size: 39px;
}
.order-stages__header span {
  display: inline-block;
  padding: 0 17px;
  background-color: #fff;
}
.order-stages__blocks {
  padding: 16px 46px;
  padding-bottom: 31px;
  display: flex;
  align-items: center;
}
.order-stages__blocks > *:not(:last-child) {
  margin-right: 17px;
}
.order-stages__block {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 62px;
  height: 62px;
  border: 1px solid #8e8e8e;
  background-color: #fff;
  border-radius: 50%;
}
.order-stages__block.active {
  border: 1px solid #006634;
  background-color: #006634;
}
.order-stages__block.active .order-stages__block-img {
  display: none;
}
.order-stages__block.active .order-stages__block-img--active {
  display: block;
}
.order-stages__block .order-stages__block-img--active {
  display: none;
}
.order-stages__line {
  width: 100%;
  height: 1px;
  background-image: linear-gradient(90deg, transparent, transparent 70%, #ffffff 70%, #ffffff 100%), linear-gradient(90deg, #b9b3a3, #b9b3a3 60%, #b9b3a3 60%, #b9b3a3 100%);
  background-size: 20px 1px, 100% 1px;
  border: none;
}
.order-stages__line.active {
  background-image: linear-gradient(90deg, transparent, transparent 70%, #ffffff 70%, #ffffff 100%), linear-gradient(90deg, #4a76ae, #4a76ae 60%, #b9b3a3 60%, #b9b3a3 100%);
}
.order-stages__line.full-active {
  background-image: linear-gradient(90deg, transparent, transparent 70%, #ffffff 70%, #ffffff 100%), linear-gradient(90deg, #4a76ae, #4a76ae 60%, #4a76ae 60%, #4a76ae 100%);
}
.order .order-content-1 {
  padding-top: 36px;
  padding-bottom: 343px;
}
.order .order-clear {
  text-align: right;
}
.order .order-clear__link {
  position: relative;
  display: inline-flex;
  align-items: center;
  color: #006634;
  font-size: 17px;
  font-weight: 400;
}
.order .order-clear__link::before {
  content: '';
  display: inline-block;
  margin-right: 9px;
  width: 10px;
  height: 10px;
  background: #ffffff url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKBAMAAAB/HNKOAAAAAXNSR0IB2cksfwAAAA9QTFRFAAAAKHC3KW67KnC6MHC/d9pPCQAAAAV0Uk5TACDgMBAJSl+4AAAAMklEQVR4nGNgZGBgEGAQMmBgVmRgVmYwMmBgMDJWBgoyKxnASbAIRNYJqFKFgQUo6AAAcQQEHysUN+sAAAAASUVORK5CYII=) center no-repeat;
}
.order-delivery {
  padding-top: 8px;
  margin-bottom: 57px;
  display: flex;
}
.order-delivery > *:not(:last-child) {
  margin-right: 33px;
}
.order-delivery__selects {
  display: flex;
  max-width: 653px;
  width: 100%;
}
.order-delivery__selects > *:not(:last-child) {
  margin-right: 23px;
}
.order-delivery__select {
  max-width: 314px;
  width: 100%;
}
.order-delivery__radios {
  display: flex;
}
.order-delivery__radios > *:not(:last-child) {
  margin-right: 34px;
}
.order-delivery__radios-header {
  margin-bottom: 29px;
  color: #707070;
  font-size: 17px;
  font-weight: 400;
}
.order-delivery__radio {
  max-width: 303px;
  /*width: 100%;*/
  flex-shrink: 0;
}
.order-delivery__button {
  font-size: 19px;
  font-weight: 700;
  padding: 11px 43px 14px 43px;
}
.order-delivery__button-wrap {
  text-align: center;
}
.order-content-2 {
  padding-top: 55px;
  padding-bottom: 110px;
}
.order-info-section--no-title {
  margin-bottom: 74px;
}
.order-info-section__header {
  margin-bottom: 21px;
  color: #707070;
  font-size: 24px;
  font-weight: 700;
  line-height: 21.73px;
}
.order-info-block {
  display: flex;
  padding: 16px 22px 15px 14px;
  margin-bottom: 11px;
  border-radius: 10px;
  align-items: center;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);
  line-height: 21.73px;
}
.order-info-block__checkbox {
  position: relative;
  cursor: pointer;
}
.order-info-block__checkbox-wrap {
  flex: 0 0 5%;
  max-width: 5%;
  width: 100%;
  align-items: center;
  display: flex;
}
.order-info-block__checkbox-input {
  position: absolute;
  left: 0;
  opacity: 0;
  z-index: -1;
}
.order-info-block__checkbox-input + .order-info-block__checkbox-check {
  border: 1px solid #c3c3c3;
  background-color: #fff;
  display: block;
  width: 33px;
  height: 33px;
  flex-shrink: 0;
  border-radius: 7px;
}
.order-info-block__checkbox-input:checked + .order-info-block__checkbox-check {
  background: #ffffff url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAVCAMAAAB1/u6nAAAAAXNSR0IB2cksfwAAADxQTFRFAAAAOm2vOHCvOnCvO26vO26vPXCvO26vPG6vPHCvO26vO26vQHCvO22vO22vO26vOm2vOm6vOm6vO2+v4u+GqgAAABR0Uk5TAGAgMP/vUNCAQLDwEL9w36/AkI8Zk6D8AAAAhElEQVR4nG2R2RaAIAgFSdEWzbL+/18TsMXwPunIGUQBVAajGYBF5zt4RMRJ0alQnLWiZOkqQqRVXO1DExVzR+9wi5UadysMne8V50chrdNPUUvQNgpuGcqO9Kx4J59Fn16b5CBwkiKrIbBV8C2D4ARtPNMM/xy9YtGPnW8xQb89T/XdXLOiBAxNu1PtAAAAAElFTkSuQmCC) center no-repeat;
}
.order-info-block__title {
  flex: 0 0 36.5%;
  max-width: 36.5%;
  width: 100%;
  color: #707070;
  font-size: 17px;
  font-weight: 700;
  padding-right: 20px;
}
.order-info-block__delivery {
  flex: 0 0 21.5%;
  max-width: 21.5%;
  width: 100%;
  color: #707070;
  font-size: 17px;
  font-weight: 400;
  padding-right: 20px;
}
.order-info-block__phone {
  flex: 0 0 20%;
  max-width: 20%;
  width: 100%;
  color: #707070;
  font-size: 17px;
  font-weight: 400;
}
.order-info-block__phone span {
  font-weight: 700;
}
.order-info-block__edit {
  display: inline-block;
  cursor: pointer;
}
.order-info-block__edit-wrap {
  flex-shrink: 0;
  flex-grow: 1;
  align-items: center;
  justify-content: flex-end;
  display: flex;
}
.order-info-block__edit-img {
  display: block;
}
.order-info-block__edit-img--disabled {
  display: none;
}
.order-info-block__edit.disabled .order-info-block__edit-img {
  display: none;
}
.order-info-block__edit.disabled .order-info-block__edit-img--disabled {
  display: block;
}
.order-address {
  padding-top: 26px;
  margin-bottom: 47px;
}
.order-address-cols {
  display: flex;
  margin-bottom: 41px;
}
.order-address-cols > *:not(:last-child) {
  margin-right: 47px;
}
.order-address-left {
  max-width: 413px;
  width: 100%;
  flex-shrink: 0;
}
.order-address-left > *:not(:last-child) {
  margin-bottom: 26px;
}
.order-address-right {
  flex-grow: 1;
}
.order-address-right > *:not(:last-child) {
  margin-bottom: 26px;
}
.order-address-right__row {
  display: flex;
  align-items: center;
}
.order-address-right__row > *:not(:last-child) {
  margin-right: 40px;
}
.order-address-right__row .input-block {
  flex-grow: 1;
}
.order-address-right__row .input-block--checkbox {
  width: 100%;
  max-width: 413px;
}
.order-address-right__row .input-block__checkbox {
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 6px;
  cursor: pointer;
}
.order-address-right__row .input-block__checkbox-field {
  position: absolute;
  left: 0;
  opacity: 0;
  z-index: -1;
}
.order-address-right__row .input-block__checkbox-field + .input-block__checkbox-check {
  border: 1px solid #c3c3c3;
  background-color: #fff;
  display: block;
  width: 21px;
  height: 21px;
  flex-shrink: 0;
  border-radius: 7px;
}
.order-address-right__row .input-block__checkbox-field:checked + .input-block__checkbox-check {
  background: #ffffff url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAVCAMAAAB1/u6nAAAAAXNSR0IB2cksfwAAADxQTFRFAAAAOm2vOHCvOnCvO26vO26vPXCvO26vPG6vPHCvO26vO26vQHCvO22vO22vO26vOm2vOm6vOm6vO2+v4u+GqgAAABR0Uk5TAGAgMP/vUNCAQLDwEL9w36/AkI8Zk6D8AAAAhElEQVR4nG2R2RaAIAgFSdEWzbL+/18TsMXwPunIGUQBVAajGYBF5zt4RMRJ0alQnLWiZOkqQqRVXO1DExVzR+9wi5UadysMne8V50chrdNPUUvQNgpuGcqO9Kx4J59Fn16b5CBwkiKrIbBV8C2D4ARtPNMM/xy9YtGPnW8xQb89T/XdXLOiBAxNu1PtAAAAAElFTkSuQmCC) center no-repeat;
  background-size: 13px;
}
.order-address-right__row .input-block__checkbox-check {
  margin-right: 15px;
}
.order-address-right__row .input-block__checkbox-title {
  color: #707070;
  font-size: 15px;
  line-height: 21.73px;
  font-weight: 400;
}
.order-content-2-button__wrap {
  padding-top: 41px;
}
.order-address-button,
.order-content-2-address-button {
  font-size: 19px;
  font-weight: 700;
  padding: 11px 34px 14px 34px;
}
.order-content-3 {
  padding-top: 54px;
  padding-bottom: 93px;
}
.order-content-3 .order-info-section {
  margin-bottom: 42px;
}
.order-cart-top {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  align-items: baseline;
}
.order-cart-title {
  color: #707070;
  font-size: 24px;
  font-weight: 700;
}
.order-cart-view-all-products {
  display: block;
  padding-bottom: 3px;
  color: #006634;
  font-size: 15px;
  border-bottom: 2px dotted #006634;
  cursor: pointer;
}
.order-cart-view-all-products:hover {
  border-bottom: 2px dotted transparent;
}
.order-cart-view-all-products__wrap {
  text-align: right;
  flex-shrink: 0;
  padding-left: 80px;
}
.order-product {
  margin-bottom: 14px;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}
.order-product__header {
  padding: 10px 19px 11px 22px;
  display: flex;
  align-items: center;
}
.order-product__body {
  display: none;
  padding: 20px;
}
.order-product__footer {
  display: none;
}
.order-product__img {
  text-align: center;
}
.order-product__idx {
  color: #000000;
  font-size: 23px;
  font-weight: 700;
  flex: 0 0 4%;
  max-width: 4%;
  width: 100%;
}
.order-product__desc-img {
  display: none;
  flex-shrink: 0;
  margin-right: 70px;
}
.order-product__title {
  flex: 0 0 47%;
  max-width: 47%;
  width: 100%;
  padding-right: 20px;
  color: #000000;
  font-size: 17px;
  font-weight: 700;
  line-height: 21.73px;
  flex-direction: column;
}
.order-product__title span {
      display: block;
    font-size: 13px;
}
.order-product__count {
  flex: 0 0 15%;
  max-width: 15%;
  width: 100%;
}
.order-product__count .products-block__count-input {
  max-width: 66px;
  padding: 9.5px;
  font-size: 21px;
}
.order-product__price {
  flex: 0 0 14%;
  max-width: 14%;
  width: 100%;
  padding-right: 20px;
  color: #010101;
  font-size: 17px;
  font-weight: 700;
  line-height: 21.73px;
}
.order-product__price span {
  font-size: 24px;
}
.order-product__cost {
  flex: 0 0 17%;
  max-width: 17%;
  width: 100%;
  color: #010101;
  font-size: 17px;
  font-weight: 700;
  line-height: 21.73px;
}
.order-product__cost-price-cur {
  display: inline;
  white-space: nowrap;
}
.order-product__cost span {
  font-size: 26px;
}
.order-product__delete {
  flex-grow: 1;
  flex-shrink: 0;
  text-align: right;
  cursor: pointer;
}
.order-cart-result__cost {
  color: #010101;
  font-size: 18px;
  line-height: 21.73px;
  /*font-weight: 700;*/
}
.order-cart-result__cost span {
  font-size: 26px;
}
.order-cart-result-top {
  display: flex;
  align-items: center;
  padding: 10px 41px 12px 22px;
  border-radius: 5px;
  background-color: #f7f7f7;
}
.order-cart-result-top__count {
  flex: none;
  max-width: none;
  flex-shrink: 0;
  width: auto;
  margin-left: 583px;
}
.order-cart-result-top__count .products-block__count-input {
  margin: 0 10px;
}
.order-cart-result-top__count-text {
  color: #010101;
  font-size: 18px;
  font-weight: 700;
}
.order-cart-result-top__cost {
  flex-grow: 1;
  text-align: right;
}
.order-cart-result-center {
  padding: 22px 41px 12px 22px;
}
.order-cart-result-center > *:not(:last-child) {
  margin-bottom: 16px;
}
.order-cart-result-center__cost {
  text-align: right;
}
.order-cart-result-bottom {
  padding-top: 45px;
  padding-right: 76px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}
.order-cart-result-bottom__price-delivery {
  align-self: flex-start;
  margin-top: 15px;
  color: #010101;
  font-size: 18px;
  font-weight: 700;
  line-height: 21.73px;
}
.order-cart-result-bottom__price-delivery span {
  font-size: 26px;
}
.order-cart-result-bottom__cost {
  position: relative;
  margin-right: 79px;
  margin-left: 103px;
  flex-shrink: 0;
}
.order-cart-result-bottom__cost-header {
  position: absolute;
  top: -27px;
  width: 100%;
  text-align: center;
  color: #010101;
  font-size: 18px;
  font-weight: 700;
  line-height: 21.73px;
}
.order-cart-result-bottom__cost-block {
  padding: 12px 53px 13px 53px;
  border-radius: 5px;
  background-color: #3b6eaf;
  color: #ffffff;
  font-size: 19px;
  font-weight: 700;
  line-height: 24.5px;
}
.order-cart-result-bottom__cost-block span {
  font-size: 29px;
}
.order-cart-result-bottom__delivery {
  display: flex;
  flex-shrink: 0;
  max-width: 386px;
}
.order-cart-result-bottom__delivery-caption,
.order-cart-result-bottom__delivery-title {
  color: #000000;
  font-size: 17px;
  line-height: 21.74px;
  font-weight: 700;
}
.order-cart-result-bottom__delivery-title {
  margin-bottom: 7px;
}
.order-cart-result-bottom__delivery-caption {
  margin-right: 19px;
}
.order-cart-result-bottom__delivery-descr {
  color: #707070;
  font-size: 15px;
  line-height: 21.74px;
  font-weight: 400;
}
.order-cart-result {
  padding-bottom: 35px;
}
.order-action {
  padding-top: 5px;
}
.order-action__field-block {
  margin-bottom: 21px;
}
.order-action__label {
  display: block;
  margin-bottom: 18px;
  color: #707070;
  font-size: 19px;
  font-weight: 700;
}
.order-action__comment {
  min-height: 146px;
  width: 650px;
  border-radius: 10px;
  border: 1px solid #bebebe;
}
.order-action__buttons > *:not(:last-child) {
  margin-right: 9px;
}
.order-action__dop-order {
  padding: 13px 15px 12px 15px;
  border-radius: 5px;
  background-color: #9d9d9d;
  border: 1px solid #9d9d9d;
  color: #fefefe;
  font-size: 19px;
  font-weight: 700;
  transition: all 0.25s;
}
.order-action__dop-order:hover {
  color: #006634;
  background-color: #ffffff;
  border: 1px solid #006634;
}
.order-action__submit {
  padding: 13px 15px 12px 15px;
  color: #fefefe;
  font-size: 19px;
  font-weight: 700;
}
.user-story {
  padding-top: 54px;
}
.user-story-header {
  margin-bottom: 62px;
  text-align: center;
  color: #006634;
  font-size: 39px;
  font-weight: 700;
  line-height: 39.73px;
}
.user-story-load {
  color: #fefefe;
  font-size: 19px;
  font-weight: 700;
  padding: 11px 46px 14px 46px;
}
.user-story-load__wrap {
  padding-top: 34px;
  padding-bottom: 63px;
  text-align: center;
}
.user-orders-header {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  padding: 0 19px;
}
.user-orders-header__col {
  text-align: center;
  color: #707070;
  font-size: 17px;
  font-weight: 700;
  line-height: 21.73px;
}
.user-orders-header__id {
  flex-basis: 9%;
  max-width: 9%;
  width: 100%;
}
.user-orders-header__date {
  flex-basis: 14%;
  max-width: 14%;
  width: 100%;
}
.user-orders-header__name {
  flex-basis: 64%;
  max-width: 64%;
  width: 100%;
}
.user-order {
  margin-bottom: 10px;
}
.user-order__header {
  padding: 22px 19px 23px 22px;
  display: flex;
  align-items: center;
  box-shadow: 0 0 6px rgba(1, 1, 1, 0.23);
  border-radius: 10px;
}
.user-order__body {
  display: none;
  padding-top: 30px;
  padding-bottom: 38px;
}
.user-order__date,
.user-order__name,
.user-order__id {
  color: #707070;
  font-size: 17px;
  font-weight: 400;
  line-height: 21.73px;
  text-align: center;
}
.user-order__id {
  flex-basis: 9%;
  max-width: 9%;
  width: 100%;
  font-weight: 700;
}
.user-order__id span {
  display: none;
}
.user-order__date {
  flex-basis: 14%;
  max-width: 14%;
  width: 100%;
}
.user-order__name {
  flex-basis: 64%;
  max-width: 64%;
  width: 100%;
}
.user-order__more {
  color: #006634;
  font-size: 17px;
  font-weight: 400;
  flex-grow: 1;
  text-align: right;
  cursor: pointer;
}
.user-order-table__header {
  display: flex;
  align-items: center;
  padding: 0 11px;
  margin-bottom: 11px;
}
.user-order-table__header-col {
  color: #343434;
  font-size: 15px;
  line-height: 21.73px;
  font-weight: 500;
}
.user-order-table__row {
  display: flex;
  align-items: center;
  padding: 13px 11px 13px 11px;
  border-radius: 10px;
}
.user-order-table__row:nth-child(odd) {
  background-color: #f7f7f7;
}
.user-order-table__row-col {
  color: #343434;
  font-size: 15px;
  font-weight: 400;
  line-height: 21.73px;
}
.user-order-table__row-col-key {
  display: none;
}
.user-order-table__row-col,
.user-order-table__header-col {
  text-align: center;
}
.user-order-table__header-article,
.user-order-table__row-article {
  text-align: left;
  flex-basis: 10%;
  max-width: 10%;
  width: 100%;
}
.user-order-table__header-name,
.user-order-table__row-name {
  flex-basis: 35%;
  max-width: 35%;
  width: 100%;
}
.user-order-table__header-voc,
.user-order-table__row-voc {
  flex-basis: 15%;
  max-width: 15%;
  width: 100%;
  margin-right: 75px;
}
.user-order-table__header-count,
.user-order-table__row-count {
  flex-basis: 7%;
  max-width: 7%;
  width: 100%;
  margin-right: 39px;
}
.user-order-table__header-cost,
.user-order-table__row-cost {
  flex-basis: 14%;
  max-width: 14%;
  width: 100%;
}
.user-order-table__header-stav,
.user-order-table__row-stav {
  flex-grow: 1;
  text-align: right;
  padding-right: 16px;
}
.user-order-table__header-stav {
  padding-right: 33px;
}
.user-order-info {
  padding-top: 24px;
}
.user-order-info__item {
  color: #222222;
  font-size: 15px;
  font-weight: 400;
  line-height: 29px;
}
.user-order-info__key {
  font-weight: 700;
}
.auth .header-link-conditions,
.auth .navigation,
.auth .basket,
.auth .btn-hamburger-wrap {
  display: none;
}
.auth .header .logo {
  height: 43px;
  border-left: none;
}
.auth-block {
  border-radius: 10px;
  border: 1px solid #e3e3e3;
  background-color: #ffffff;
  max-width: 652px;
  width: 100%;
  margin: 0 auto;
  margin-top: 76px;
  margin-bottom: 146px;
}
.auth-header {
  display: inline-flex;
  align-items: center;
  padding: 0 23px;
  background-color: #fff;
}
.auth-header__text {
  color: #006634;
  font-size: 39px;
  font-weight: 700;
}
.auth-header__icon {
  margin-right: 11px;
}
.auth-header__wrap {
  text-align: center;
  margin-top: -22px;
  margin-bottom: 16px;
}
.auth-subheader {
  text-align: center;
  color: #000000;
  font-size: 17px;
  margin-bottom: 29px;
}
.auth-form .radio__label {
  margin-bottom: 0;
}
.auth-form__wrap {
  padding: 0 38px 54px 20px;
}
.auth-form-block {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 18px;
}
.auth-form-block__label {
  margin-right: 27px;
  color: #707070;
  font-size: 17px;
  font-weight: 400;
}
.auth-form-block__input {
  box-shadow: 0 0 6px rgba(1, 1, 1, 0.23);
  border-radius: 10px;
  padding: 18px 20px;
  font-size: 15px;
  max-width: 423px;
  width: 100%;
  border: none;
}
.auth-form-block__input::-webkit-input-placeholder {
  color: #7a7a7a;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 15px;
}
.auth-form-block__input:-ms-input-placeholder {
  color: #7a7a7a;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 15px;
}
.auth-form-block__input::placeholder {
  color: #7a7a7a;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 15px;
}
.auth-form-block__input:focus {
  box-shadow: 0 0 6px #006634;
}
.auth-form-country__right,
.auth-form-radios__right {
  max-width: 423px;
  width: 100%;
  display: flex;
  align-items: center;
}
.auth-form-country .select {
  flex-shrink: 0;
  margin-right: 16px;
  max-width: 84px;
  width: 100%;
}
.auth-form-country .select__curr {
  padding: 16px 30px 18px 14px;
  height: 54px;
  min-height: 54px;
}
.auth-form-country .select__curr::after {
  right: 11px;
}
.auth-form-country .auth-form-block__input::-webkit-input-placeholder {
  color: #777777;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 19px;
}
.auth-form-country .auth-form-block__input:-ms-input-placeholder {
  color: #777777;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 19px;
}
.auth-form-country .auth-form-block__input::placeholder {
  color: #777777;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 19px;
}
.auth-form-radios {
  padding: 16px 0 16px 0;
}
.auth-form-radios .radio {
  margin-right: 32px;
}
.auth-form-radios .radio__check {
  margin-right: 10px;
}
.auth-form-radios .radio__title {
  color: #000000;
  font-size: 17px;
  font-weight: 300;
}
.auth-form-personal {
  max-width: 423px;
  margin-left: auto;
  display: flex;
  align-items: center;
  margin-top: 26px;
}
.auth-form-personal__link {
  color: #006634;
  font-size: 14px;
  font-weight: 300;
}
.auth-form-personal__link:hover {
  text-decoration: underline;
  color: #006634;
}
.auth-form-personal .radio {
  margin-right: 32px;
}
.auth-form-personal .radio__title {
  color: #000000;
  font-size: 17px;
  font-weight: 300;
}
.auth-form-personal .radio__check {
  margin-right: 10px;
}
.auth-form-register__btn {
  padding: 12px 30px 13px 30px;
  color: #fefefe;
  font-size: 19px;
  font-weight: 700;
}
.auth-form-register__btn-wrap {
  max-width: 423px;
  width: 100%;
  margin-left: auto;
  padding-top: 38px;
}
.login .auth-form__wrap {
  padding: 0 38px 68px 20px;
}
.login .auth-header__wrap {
  margin-bottom: 36px;
}
.login .auth-form-checkbox {
  margin-top: 31px;
}
.login .auth-form-checkbox .radio__title {
  color: #696868;
}
.login .auth-form-checkbox .radio__check {
  margin-right: 15px;
}
.login .auth-form-register__btn-wrap {
  padding-top: 28px;
}
.login .auth-form-register__btn-wrap > *:not(:last-child) {
  margin-right: 10px;
}
.login .auth-form-register__btn:nth-child(1) {
  padding: 12px 42px 13px 42px;
}
.login .auth-form-register__btn:nth-child(2) {
  color: #006634;
  border-color: #88b49f;
  font-size: 17px;
  font-weight: 500;
  padding: 14px 35px 14px 35px;
}
.login .auth-form-register__btn:nth-child(2):hover {
  color: #ffffff;
  border-color: #006634;
}
.footer {
  padding-top: 34px;
  padding-bottom: 42px;
  flex-shrink: 0;
  box-shadow: 0px -23px 32px -28px rgba(0, 0, 0, 0.27);
}
.footer .container {
  display: flex;
  align-items: center;
}
.footer__logo {
  display: block;
  max-width: 542px;
  width: 100%;
  padding-left: 22px;
}
.footer__copyright {
  color: #707070;
  font-size: 14px;
  font-weight: 400;
  line-height: 21.73px;
}
.modal-contacts {
  position: fixed;
  z-index: 1110000;
  top: 0;
  left: 0;
  visibility: hidden;
  width: 100%;
  height: 100%;
}
.modal-contacts.modal-contacts__is-visible {
  visibility: visible;
}
.modal-contacts.modal-contacts__is-visible .modal-contacts__overlay {
  opacity: 1;
  visibility: visible;
  transition-delay: 0s;
}
.modal-contacts.modal-contacts__is-visible .modal-contacts__transition {
  transform: translateY(0) translateX(-50%);
  opacity: 1;
}
.modal-contacts__overlay {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s linear 0.3s,
    opacity 0.3s;
}
.modal-contacts__wrapper {
  position: absolute;
  z-index: 9999;
  top: 10px;
  left: 50%;
  max-width: 208px;
  width: 100%;
  background-color: #fff;
  box-shadow: 0 0 4px rgba(1, 1, 1, 0.35);
  border-radius: 5px;
}
.modal-contacts__transition {
  transition: all 0.3s 0.12s;
  transform: translateY(-10%) translateX(-50%);
  opacity: 0;
}
.modal-contacts__body {
  position: relative;
  padding: 23px 12px 22px 12px;
}
.modal-contacts__phones {
  text-align: center;
  border-bottom: 1px solid #e8e8e8;
  padding-bottom: 20px;
}
.modal-contacts__phones > *:not(:last-child) {
  margin-bottom: 15px;
}
.modal-contacts__phone {
  display: block;
  color: #111111;
  font-size: 17px;
  font-weight: 300;
}
.modal-contacts__phone span {
  font-size: 19px;
  font-weight: 700;
}
.modal-contacts__phone:hover {
  color: #111111;
  text-decoration: underline;
}
.modal-contacts__massagers {
  padding-left: 15px;
  padding-top: 15px;
  padding-bottom: 20px;
  border-bottom: 1px solid #e8e8e8;
}
.modal-contacts__massagers > *:not(:last-child) {
  margin-bottom: 12px;
}
.modal-contacts__massager {
  display: flex;
  align-items: center;
}
.modal-contacts__massager-icon {
  flex-shrink: 0;
  margin-right: 15px;
}
.modal-contacts__massager-title {
  color: #111111;
  font-size: 15px;
  font-weight: 300;
}
.modal-contacts__mails {
  padding-top: 18px;
  text-align: center;
}
.modal-contacts__mails > *:not(:last-child) {
  margin-bottom: 20px;
}
.modal-contacts__mail {
  display: block;
  color: #111111;
  font-size: 15px;
  font-weight: 300;
}
.modal-contacts__mail:hover {
  color: #111111;
  text-decoration: underline;
}
.modal-contacts__close {
  position: absolute;
  top: 7px;
  right: 7px;
  width: 7px;
  height: 7px;
  cursor: pointer;
  background: #ffffff url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAHBAMAAAA2fErgAAAAAXNSR0IB2cksfwAAABhQTFRFAAAAj4+Pk5OTlZWVlJSUkpKSk5OTj4+PBjq57AAAAAh0Uk5TACDgMF9g/xCaFfH7AAAAKElEQVR4nGNgZGBkYBAyMBJgYFZRZmBgCAtgYGBWBbKKDIwKGNiBEAAt0AMUmgd6TAAAAABJRU5ErkJggg==) center no-repeat;
}
.message-alert {
  position: fixed;
  top: 50%;
  left: 50%;
  max-width: 582px;
  width: 100%;
  min-height: 159px;
  transform: translateX(-50%) translateY(-50%);
  box-shadow: 0 0 6px rgba(1, 1, 1, 0.23);
  border-radius: 5px;
  background-color: #ffffff;
  text-align: center;
  padding: 25px 10px;
}
.message-alert__close {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 10px;
  height: 10px;
  cursor: pointer;
  background: #ffffff url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKBAMAAAB/HNKOAAAAAXNSR0IB2cksfwAAAA9QTFRFAAAAn5+fnZ2dn5+fn5+fECk9ywAAAAV0Uk5TACDgMBAJSl+4AAAAMklEQVR4nGNgZGBgEGAQMmBgVmRgVmYwMmBgMDJWBgoyKxnASbAIRNYJqFKFgQUo6AAAcQQEHysUN+sAAAAASUVORK5CYII=) center no-repeat;
}
.message-alert__title {
  margin-bottom: 29px;
  color: #006634;
  font-size: 25px;
  line-height: 21.73px;
  font-weight: 700;
}
.message-alert__descr {
  color: #000000;
  font-size: 16px;
  line-height: 21.73px;
}
@media (min-width: 1366px) {
  .products-block-color__wrapper {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -29px;
  }
  .products-block-color__item {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
    position: relative;
    margin-bottom: 26px;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
  }
  .user-order__more::after {
    content: '';
    display: inline-block;
    margin-left: 15px;
    width: 16px;
    height: 9px;
    transform: rotate(0);
    vertical-align: middle;
    background: #ffffff url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAJCAYAAAA7KqwyAAAAzElEQVQokY3RPUoDURTF8d9MBgvBNSl+hKSSgApp7e1cgAsQhrSWqUTICiwsXIKNIkGLFIHBVtBGC48yjKIeeNzHuf/D5b1b1HW9gWMc4Nn/tIoLnFY4wTpm2MXrH+GVsAOsldjHTYxzVL+EqzCDZPZKNOjjHiNM0fsh3EtvFLaPpkxziU08YIwzFK1wEW+Mx7BLKFvQAjuph5i0epN4C2ynfr2prXmAaxzhBW+5Nxkw735KV3fYwpWP9cJTvNsuXHaN6HMrlznDeN/0DubALb4t5ARXAAAAAElFTkSuQmCC) center no-repeat;
    transition: transform .3s;
  }
  .user-order.opened .user-order__more::after {
    transform: rotate(180deg);
  }
  .order-product__title {
    flex: auto;
    width: auto;
    flex-grow: 1;
  }
  .order-product__delete {
    flex-grow: 0;
    margin-left: auto;
  }
  .order-product__body {
    display: none !important;
  }
}
@media (max-width: 1365px) {
  .order-product__desc-img {
    display: none !important;
  }
  .container {
    max-width: 768px;
  }
  .btn-hamburger-wrap {
    display: flex;
    z-index: 111111;
  }
  .header {
    background-color: #006634;
    border-top: none;
    padding-top: 0;
    height: 43px;
    padding-bottom: 0;
  }
  .header .container {
    align-items: stretch;
    padding-left: 0;
    padding-right: 0;
    max-width: none;
  }
  .header-link-conditions {
    display: none;
  }
  .header .logo {
    margin-right: 0;
    display: flex;
    flex-grow: 1;
    padding-left: 12px;
    border-left: 1px solid #245c9a;
    border-right: 1px solid #5393d9;
    height: auto;
    align-items: center;
  }
  .navigation {
    padding-top: 47px;
    padding-bottom: 15px;
    background: #f6f6f6;
    height: auto;
    left: -100%;
    position: absolute;
    top: 0;
    z-index: 1000;
    width: 100%;
    transition: all 300ms ease-in-out;
  }
  .navigation.opened {
    left: 0;
  }
  .navigation__list {
    display: block;
    height: auto;
    padding: 0;
    overflow: auto;
    max-height: 100vh;
  }
  .navigation__list > *:not(:last-child) {
    margin-right: 0;
  }
  .navigation__item {
    padding-bottom: 0;
    margin-right: 0;
    padding-top: 0;
    transition: all 200ms ease-in-out;
  }
  .navigation__link {
    display: block;
    padding: 14px 18px 14px 22px;
    background: #f6f6f6;
    color: #707070;
    font-size: 17px;
    font-weight: 400;
  }
  .navigation__link.active {
    font-weight: 700;
    background: #e9e9e9;
  }
  .contacts {
    position: relative;
    top: 0;
    left: 0;
    width: 43px;
    height: auto;
    border-radius: 0;
    border-left: 1px solid #245c9a;
    background-color: #006634;
  }
  .basket {
    position: relative;
    top: 0;
    left: 0;
    width: 45px;
    height: auto;
    border-radius: 0;
    background-color: #21558f;
  }
  .basket__count {
    color: #21558f;
    font-size: 13px;
    font-weight: 700;
    top: 2px;
    left: 24px;
    padding: 0px 2px 0px 2px;
    height: 17px;
    min-width: 17px;
    background-color: #fff;
  }
  .products {
    padding-top: 27px;
  }
  .products-menu-mobile-wrap {
    display: block;
  }
  .products-header {
    position: relative;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 80px;
  }
  .products-header__left {
    margin-top: 23px;
    margin-bottom: 15px;
    flex-basis: auto;
  }
  .products-header__right {
    flex-direction: column;
    align-items: flex-start;
  }
  .products-header__view-all-products {
    margin-bottom: 11px;
  }
  .search {
    position: absolute;
    top: 22px;
    left: 0;
    right: 0;
  }
  .products-body {
    flex-wrap: wrap;
  }
  .products-body__left {
    display: none;
  }
  .products-menu-mobile {
    display: none;
    position: absolute;
    z-index: 10;
    top: 100%;
    left: 0;
    width: 100%;
    background: #fff;
    box-shadow: 0 3px 6px 0px rgba(1, 1, 1, 0.23);
    border-radius: 10px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .products-menu-mobile__caption {
    margin-bottom: 10px;
    color: #707070;
    font-size: 17px;
    font-weight: 400;
  }
  .products-menu-mobile__container {
    position: relative;
  }
  .products-menu-mobile__curr {
    position: relative;
    display: block;
    color: #707070;
    font-size: 17px;
    padding: 16px 40px 18px 16px;
    font-weight: 400;
    line-height: 21.73px;
    box-shadow: 0 0 6px rgba(1, 1, 1, 0.23);
    border-radius: 10px;
  }
  .products-menu-mobile__curr.opened {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .products-menu-mobile__curr.opened::after {
    transform: translateY(-50%) rotate(180deg);
  }
  .products-menu-mobile__curr::after {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%) rotate(0);
    transition: transform .3s;
    right: 15px;
    display: inline-block;
    width: 16px;
    height: 16px;
    background: #ffffff url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAJCAYAAAA7KqwyAAAAzElEQVQokY3RPUoDURTF8d9MBgvBNSl+hKSSgApp7e1cgAsQhrSWqUTICiwsXIKNIkGLFIHBVtBGC48yjKIeeNzHuf/D5b1b1HW9gWMc4Nn/tIoLnFY4wTpm2MXrH+GVsAOsldjHTYxzVL+EqzCDZPZKNOjjHiNM0fsh3EtvFLaPpkxziU08YIwzFK1wEW+Mx7BLKFvQAjuph5i0epN4C2ynfr2prXmAaxzhBW+5Nxkw735KV3fYwpWP9cJTvNsuXHaN6HMrlznDeN/0DubALb4t5ARXAAAAAElFTkSuQmCC) center no-repeat;
  }
  .products-menu-mobile__item:nth-child(even) .products-menu-mobile__header {
    background-color: #e7e7e7;
  }
  .products-menu-mobile__item:nth-child(1) .products-menu-mobile__header::before {
    content: '';
    position: absolute;
    top: 0;
    left: 4px;
    width: calc(100% - 8px);
    height: 1px;
    background-color: #e7e7e7;
  }
  .products-menu-mobile__header {
    position: relative;
    display: block;
    color: #707070;
    font-size: 17px;
    padding: 16px 16px 18px 16px;
    font-weight: 700;
    line-height: 21.73px;
  }
  .products-submenu-mobile {
    display: none;
  }
  .products-submenu-mobile__link {
    position: relative;
    display: block;
    color: #707070;
    font-size: 17px;
    padding: 16px 16px 18px 30px;
    font-weight: 400;
    line-height: 21.73px;
  }
  .products-blocks {
    padding-top: 21px;
  }
  .products-block-header {
    flex-wrap: wrap;
    padding: 13px 9px 13px 13px;
  }
  .products-block-footer {
    position: relative;
    display: block;
    padding-top: 12px;
    padding-bottom: 17px;
  }
  .products-block-footer::before {
    content: '';
    position: absolute;
    top: 0;
    left: 10px;
    width: calc(100% - 20px);
    height: 1px;
    background-color: #e7e7e7;
  }
  .products-block__icon {
    align-self: flex-start;
    flex: 0 0 53px;
    max-width: 53px;
    width: 100%;
  }
  .products-block__title {
    max-width: calc(100% - 53px);
    flex-shrink: 0;
    flex-grow: 0;
    flex-basis: calc(100% - 53px);
    width: 100%;
    margin-bottom: 38px;
    margin-top: 6px;
  }
  .products-block__more {
    display: none;
  }
  .products-block__more--mobile {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
    width: 100%;
    text-align: center;
    padding-right: 0px;
  }
  .products-block__price {
    text-align: left;
    flex: 0 0 30%;
    max-width: 30%;
    width: 100%;
  }
  .products-block--mod-1 .products-block-header .products-block__count {
    flex: 0 0 43%;
    max-width: 43%;
    width: 100%;
    justify-content: center;
    padding-right: 0;
  }
  .products-block--mod-2 .products-block-header .products-block__count {
    order: 1;
    justify-content: flex-end;
    padding-right: 0;
  }
  .products-block--mod-3 .products-block__title {
    margin-bottom: 14px;
  }
  .products-block--mod-3 .products-block__price {
    margin-bottom: 25px;
    flex: 0 0 100%;
    max-width: 100%;
    width: 100%;
    padding-left: 53px;
  }
  .products-block--mod-3 .products-block-header .products-block__count {
    justify-content: flex-start;
  }
  .products-block-header .products-block__count .products-block__count-input {
    max-width: 42px;
    padding: 3px 4px;
  }
  .products-block__cost {
    text-align: left;
    font-size: 14px;
    flex: 0 0 27%;
    max-width: 27%;
    width: 100%;
    line-height: 15.93px;
  }
  .products-block__cost span {
    font-size: 16px;
  }
  .products-block--mod-1 .products-block__cost {
    flex-grow: 0;
    text-align: left;
  }
  .products-block--mod-2 .products-block__cost {
    padding-left: 0;
    text-align: left;
    font-size: 14px;
    flex: 0 0 27%;
    max-width: 27%;
    width: 100%;
    line-height: 15.93px;
  }
  .products-block--ordered .products-block__title {
    margin-bottom: 11px;
  }
  .products-block--ordered .products-block__status {
    order: 1;
    margin-bottom: 6px;
    flex: 0 0 100%;
    max-width: 100%;
    width: 100%;
    text-align: center;
    padding-right: 0;
  }
  .products-block--ordered .products-block__price {
    order: 2;
  }
  .products-block--ordered .products-block-header .products-block__count {
    order: 3;
    flex: 0 0 43%;
    max-width: 43%;
    width: 100%;
    justify-content: center;
    padding-right: 0;
  }
  .products-block--ordered .products-block__cost {
    order: 4;
    text-align: left;
    font-size: 14px;
    flex: 0 0 27%;
    max-width: 188px;
    width: 100%;
    line-height: 15.93px;
  }
  .products-block .products-block-tabs {
    display: none;
  }
  .products-body__right {
    max-width: 100%;
  }
  .products-block-color.slider-active {
    width: 100%;
    height: 100%;
    touch-action: pan-y;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    overflow: hidden;
    list-style: none;
    padding: 0;
    z-index: 1;
  }
  .products-block-color__wrapper {
    margin-bottom: 24px;
  }
  .products-block-color {
    margin-bottom: 37px;
  }
  .products-block .products-block-color.slider-destroy {
    display: block;
  }
  .products-block-color.slider-destroy .products-block-color__wrapper {
    display: flex;
    flex-wrap: wrap;
  }
  .products-block-color.slider-destroy .products-block-color__item {
    flex-basis: 50%;
    padding: 0 10px;
  }
  .products-block-color.slider-destroy .products-block-color__nav {
    display: none;
  }
  .products-block-color.slider-active .products-block-color__wrapper {
    transform: translate3d(0px, 0, 0);
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    transition-property: transform;
    box-sizing: content-box;
  }
  .products-block-color.slider-active .products-block-color__item {
    padding: 0 25px;
    text-align: center;
    font-size: 18px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 100%;
    height: 100%;
    position: relative;
    transition-property: transform;
  }
  .products-block .products-block-tabs-content__pane {
    display: block;
  }
  .products-block .products-block-tabs-content__pane-tab-mobile {
    position: relative;
    display: block;
    border-radius: 5px;
    background-color: #eaeaea;
    color: #282828;
    font-size: 16px;
    font-weight: 700;
    padding: 14px 35px 15px 16px;
    margin: 0 10px;
    margin-bottom: 7px;
  }
  .products-block .products-block-tabs-content__pane-tab-mobile.mobile-opened::after {
    transform: translateY(-50%) rotate(180deg);
  }
  .products-block .products-block-tabs-content__pane-tab-mobile::after {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%) rotate(0);
    transition: transform .3s;
    right: 18px;
    display: inline-block;
    width: 12px;
    height: 12px;
    background: #eaeaea url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAAGCAYAAAAYLBS/AAAAi0lEQVQYlX3OsQpBcQCF8d+9XZltymjxGLIYMMokLyAjeQqDJ7AwKBnIeh/FoozqWhiw/G/dFGc8ne/rRGmaRthhja3/GaKT4I0xjqhi+QOYoo9uEooLmtijhnmQQYwFGmghiwumG9qoY4USytiggh6y3FLMA4MgOOCEM0Z45qNvCF6Y4I4rZoWr4AOejBzR+9OT9AAAAABJRU5ErkJggg==) center no-repeat;
  }
  .products-block .products-block-tabs-content__pane-content {
    display: none;
  }
  .products-block-body {
    padding: 8px 0px 14px 0px;
  }
  .products-block-color__nav {
    position: absolute;
    z-index: 10;
    top: 50%;
    transform: translateY(-50%);
    width: 25px;
    height: 44px;
    background-color: #42a4e6;
  }
  .products-block-color__nav--prev {
    left: 0;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    background: #42a4e6 url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAVCAYAAAB7R6/OAAAAsElEQVQokZ2SUQpBQRiFv3t54InYgezh5l3WoazDDihWIAuwASkvJHuQPcgCPi+udM0v+WtqZjpnTuecyVSCaQAL1GjN1CIP2CPgBpxTzK66UWsqKcBa7ZXnqsQE2APX180bs6+uqi+Wm/pTtxMBpuowZTcHCqAF7JKG1aPajgJDHajzCJADJ+AODCOJn1yEObwneQEOwDglEXbxV5uoo2e6H22WswXaQJF9+ZNNYPkAQONSDbDC73gAAAAASUVORK5CYII=) center no-repeat;
  }
  .products-block-color__nav--next {
    right: 0;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    background: #42a4e6 url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAVCAYAAAB7R6/OAAAArUlEQVQokY2SsQkCURBEn56BmaIdqD1cB1eHYB3XgQVodFiAGJlcYmJyPYg9iAU8k39wyH5xYLKBtzu7qAd1qhIZtVT3ucAY6IAXUBEpJQv1rC4jRO+VeooQvZ7ADdhFiKEbdR0hei/SPEUugFqp9fcMQ7XADChHaibDHLjmEKR2yxyiAt5Al9viok5yWzTqJmoSYAvcgUfUZHiLv69Zp/bChylTa224sHr89ZMffFNm9uAgLewAAAAASUVORK5CYII=) center no-repeat;
  }
  .view-all-colors {
    display: inline-block;
    color: #006634;
    font-size: 15px;
    font-weight: 400;
    padding-bottom: 3px;
    border-bottom: 2px dotted #006634;
  }
  .view-all-colors__wrap {
    display: block;
    text-align: center;
  }
  .products-block-chars {
    padding-top: 10px;
    padding-bottom: 22px;
  }
  .products-block-chars__item {
    padding: 8px 18px 8px 18px;
    margin-right: 0px;
    font-size: 12px;
    max-width: none;
  }
  .products-block-chars__key {
    font-weight: 500;
  }
  .products-block-chars__value {
    text-align: right;
  }
  .products-block-chars__item:nth-child(4n+3),
  .products-block-chars__item:nth-child(4n+4) {
    padding: 8px 18px 8px 18px;
  }
  .products-block-chars__item:nth-child(odd) {
    background-color: #f3f3f3;
  }
  .products-block-chars__item:nth-child(even) {
    background-color: #fff;
  }
  .products-block-descr {
    padding: 0 17px;
    padding-top: 15px;
    padding-bottom: 19px;
  }
  .products-main-container {
    margin-bottom: 137px;
  }
  .products-footer .container {
    flex-wrap: wrap;
  }
  .products-footer-order {
    width: 100%;
    justify-content: space-between;
  }
  .products-footer-order__icon {
    display: none;
  }
  .products-footer__left {
    max-width: none;
  }
  .products-footer__right {
    padding-left: 0;
  }
  .products-footer__left::after {
    content: '';
    position: absolute;
    top: 0;
    left: 100%;
    display: block;
    height: 100%;
    width: 100vw;
    background-color: #e4e4e4;
  }
  .products-footer__right::after {
    content: '';
    position: absolute;
    top: 0;
    right: 100%;
    display: block;
    height: 100%;
    width: 100vw;
    background-color: #f0f0f0;
  }
  .footer {
    padding-top: 34px;
    padding-bottom: 49px;
  }
  .footer .container {
    flex-direction: column;
  }
  .footer__logo {
    padding-left: 0;
    text-align: center;
    margin-bottom: 23px;
  }
  .order {
    padding-top: 22px;
  }
  .order .order-content-1,
  .order .order-content-2,
  .order .order-content-3 {
    padding-top: 26px;
    padding-bottom: 214px;
  }
  .order-stages {
    display: none;
  }
  .order-back-link__wrap {
    margin-right: 0;
  }
  .order .order-clear {
    display: none;
  }
  .order-delivery {
    flex-direction: column;
    margin-bottom: 42px;
  }
  .order-delivery > *:not(:last-child) {
    margin-right: 0;
    margin-bottom: 38px;
  }
  .order-delivery__selects {
    flex-direction: column;
    max-width: none;
  }
  .order-delivery__selects > *:not(:last-child) {
    margin-right: 0;
    margin-bottom: 26px;
  }
  .order-delivery__select {
    max-width: none;
  }
  .order-delivery__radios {
    flex-wrap: wrap;
    flex-direction: column;
  }
  .order-delivery__radios > *:not(:last-child) {
    margin-right: 0;
    margin-bottom: 15px;
  }
  .order-delivery__radio {
    max-width: none;
  }
  .order-delivery__button-wrap {
    text-align: left;
  }
  .order-info-block {
    padding: 16px 14px 32px 14px;
    flex-wrap: wrap;
  }
  .order-info-block__checkbox-wrap {
    flex: 0 0 50%;
    order: -1;
    max-width: 50%;
    width: 100%;
    align-self: flex-start;
  }
  .order-info-block__edit-wrap {
    flex: 0 0 50%;
    order: -1;
    max-width: 50%;
    margin-bottom: 18px;
  }
  .order-info-block__title {
    flex: 0 0 100%;
    max-width: 100%;
    padding-right: 0;
    margin-bottom: 17px;
  }
  .order-info-block__delivery {
    flex: 0 0 100%;
    max-width: 100%;
    padding-right: 0;
    margin-bottom: 24px;
  }
  .order-info-block__phone {
    flex: 0 0 100%;
    max-width: 100%;
    padding-right: 0;
  }
  .order-address-cols {
    flex-direction: column;
  }
  .order-address-cols > *:not(:last-child) {
    margin-right: 0;
  }
  .order-address-left {
    max-width: none;
    margin-bottom: 26px;
  }
  .order-address-right > *:not(:last-child) {
    margin-bottom: 26px;
  }
  .order-address-right__row {
    flex-direction: column;
    align-items: stretch;
  }
  .order-address-right__row > *:not(:last-child) {
    margin-right: 0;
    margin-bottom: 26px;
  }
  .order-cart-top {
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: 20px;
  }
  .order-cart-title {
    margin-bottom: 15px;
  }
  .order-cart-view-all-products__wrap {
    padding-left: 0;
    text-align: left;
  }
  .order-product__header {
    position: relative;
    padding: 16px 40px 20px 40px;
  }
  .order-product__idx {
    position: absolute;
    top: 17px;
    left: 10px;
    font-size: 18px;
    flex: auto;
    max-width: none;
  }
  .order-product__delete {
    position: absolute;
    top: 14px;
    right: 10px;
  }
  .order-product__title {
    flex: auto;
    max-width: none;
    font-size: 16px;
    padding-right: 0;
    text-align: center;
  }
  .order-product__price {
    display: none;
    flex: 0 0 34%;
    max-width: 34%;
    width: 100%;
    text-align: left;
    padding-right: 0;
  }
  .order-product__price span {
    font-size: 20px;
  }
  .order-product__count {
    display: none;
    flex: 0 0 38%;
    max-width: 38%;
    width: 100%;
  }
  .order-product__count .products-block__count-input {
    max-width: 37px;
    padding: 2px 4px;
    font-size: 19px;
  }
  .order-product__cost {
    display: none;
    flex: auto;
    max-width: none;
    width: 100%;
    line-height: 15.93px;
    font-size: 14px;
    flex-grow: 1;
  }
  .order-product__cost span {
    font-size: 16px;
  }
  .order-product__body {
    padding-top: 0;
  }
  .order-product__footer {
    position: relative;
    display: flex;
    padding: 12px 10px 16px 10px;
    align-items: center;
  }
  .order-product__footer .order-product__price,
  .order-product__footer .order-product__cost {
    display: block;
  }
  .order-product__footer .order-product__count {
    display: flex;
  }
  .order-product__footer::before {
    content: '';
    position: absolute;
    top: 0;
    left: 10px;
    width: calc(100% - 20px);
    height: 1px;
    background-color: #e7e7e7;
  }
  .order-cart-result-top {
    position: relative;
    padding: 10px 0 12px 0;
    flex-direction: column;
  }
  .order-cart-result-top::before,
  .order-cart-result-top::after {
    content: '';
    position: absolute;
    top: 0;
    display: block;
    height: 100%;
    width: 100vw;
    background-color: #f7f7f7;
  }
  .order-cart-result-top::before {
    right: 100%;
  }
  .order-cart-result-top::after {
    left: 100%;
  }
  .order-cart-result-top__count {
    display: flex;
    flex: none;
    max-width: none;
    flex-shrink: 0;
    width: auto;
    margin-left: 0;
    margin-bottom: 16px;
  }
  .order-cart-result-top__count .products-block__count-input {
    max-width: 53px;
    padding: 6px;
    font-size: 21px;
  }
  .order-cart-result-center {
    border-bottom: 1px solid #e7e7e7;
    padding: 20px 0 18px 0;
  }
  .order-cart-result-center > *:not(:last-child) {
    margin-bottom: 10px;
  }
  .order-cart-result-bottom__cost-block {
    padding: 11px 39px 8px 39px;
  }
  .order-cart-result-bottom {
    padding-top: 6px;
    padding-right: 0;
    flex-direction: column;
    align-items: center;
  }
  .order-cart-result-bottom__price-delivery {
    margin-bottom: 55px;
    align-self: center;
  }
  .order-cart-result-bottom__cost {
    margin-right: 0;
    margin-left: 0;
    margin-bottom: 30px;
  }
  .order-cart-result-bottom__delivery {
    flex-direction: column;
    flex-shrink: 0;
    align-self: flex-start;
    max-width: none;
  }
  .order-cart-result-bottom__delivery-caption {
    margin-bottom: 13px;
    color: #707070;
    font-weight: 400;
  }
  .order-cart-result {
    padding-bottom: 50px;
  }
  .order-action__comment {
    width: 100%;
  }
  .order-action__buttons {
    text-align: center;
  }
  .user-story {
    padding-top: 18px;
  }
  .user-story-header {
    font-size: 29px;
    margin-bottom: 22px;
  }
  .user-orders-header {
    display: none;
  }
  .user-order__header {
    flex-wrap: wrap;
    align-items: flex-start;
    padding: 15px 18px 17px 18px;
  }
  .user-order__id {
    flex-basis: 50%;
    max-width: 50%;
    text-align: left;
    margin-bottom: 22px;
  }
  .user-order__id span {
    display: inline-block;
  }
  .user-order__date {
    flex-basis: 50%;
    max-width: 50%;
    text-align: right;
  }
  .user-order__name {
    flex-basis: 100%;
    max-width: 100%;
    text-align: left;
    margin-bottom: 22px;
  }
  .user-order__more {
    font-size: 16px;
  }
  .user-order__more::after {
    content: '';
    display: inline-block;
    margin-left: 8px;
    width: 20px;
    height: 13px;
    vertical-align: middle;
    background: #ffffff url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAMAAACejr5sAAAAAXNSR0IB2cksfwAAAUdQTFRFAAAALmi5Km66Km27KW67KW67KW67KW67KW+6M2bMKW26KW67KW67KW67Km67Km+6Km+8Km67KW67KG68J2y6KW68KW67KXC9JG22Ima7KG65KG67KW67JnG9KW67KW67KG+7LHC7K228KG+8Lm2/IGC/KW67KW67J2y6JG22KW67KG+7KW27KW66KW67KW27Km27KW66HHHGKW+7KG26KW+7K224KGy7Km67KW+8KW67KW68KW66KG67KW27JG22KnC6J2+8KG+6KG+7KW+8KW67KW27JnO/KW68KW67KG68KW67KW67KW67K228J2++KG+8Km67Jmy5Km+6KW27KXC4KW67K2+8Kmq/J3bEKm67KW66LHK5AICAKm28KW67KW68KG67KW27KW67KW67KW68KoCqK3G4KG68KW67Km68Km67KGu8Z9GZkgAAAG10Uk5TABZouOb6+eS2BXfx/9KZep/X8nkawakyDg8zpMIb2fZTKSpMHAjI8zQV4Olwb+jiMckJikZxLy10Re+UnJ6/BzAuc0eIzzgU3+ty6tz4VCe9vihV2xm7NQwNrMUdAm7to398ndbuBhJm97JhE3UHplIAAADiSURBVHicPY/XUgJREEQPsKKsYwABE+4qLi4ggoA5oYARc8Kcxfj/z94rpf3Q1d010zUDGh6vz2jxt7Z5PfwhYLZLR6fZ1R2UUE+gmYUjEu2Fvn4YGJRYWGdDlj0MIxGRWBxGQ5YDiTE3Can0eGYim1OVk26+QFGm1Pi0zMCszCk5L0UWFnXHUlazsax5xSZe0iIjq7AmZSUrUoXk+gZsbm2Xd2rplLJuPgHO7t4+HByKHB3DiX3q6NWzc4nW4eIS6ldyfdO8/vbuXqyHx6fnF3k1G/+Pvr37DP9H7vPr+9f+APCSG/YZmgLjAAAAAElFTkSuQmCC) center no-repeat;
  }
  .user-order.opened .user-order__more {
    color: #b6b6b6;
  }
  .user-order.opened .user-order__more::after {
    background: #ffffff url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAMAAACejr5sAAAAAXNSR0IB2cksfwAAAUdQTFRFAAAAxcXFycnJycnJycnJycnJycnJycnJyMjIzMzMycnJycnJycnJysrKysrKycnJyMjIyMjIycnJyMjIxMTEycnJycnJx8fHyMjIzMzMyMjIycnJycnJxsbGycnJycnJyMjIx8fHyMjIycnJyMjIv7+/ycnJycnJycnJzs7OycnJycnJyMjIyMjIycnJycnJy8vLyMjIxsbGycnJyMjIycnJycnJxsbGyMjIyMjIycnJysrKycnJysrKysrK29vbysrKyMjIysrKycnJycnJycnJyMjIzMzMycnJycnJycnJycnJycnJycnJyMjIy8vLycnJycnJzMzMycnJycnJzMzMyMjIysrKv7+/xMTEysrKycnJysrK////ysrKycnJyMjIycnJysrKycnJycnJycnJ1dXVxsbGyMjIycnJycnJyMjIycnJapcahQAAAG10Uk5TABZouOb6+eS2BXfx/9KZep/X8nkawakyDg8zpMIb2fZTKSpMHAjI8zQV4Olwb+jiMckJikZxLy10Re+UnJ6/BzAuc0eIzzgU3+ty6tz4VCe9vihV2xm7NQwNrMUdAm7to398ndbuBhJm97JhE3UHplIAAADiSURBVHicPY/XUgJREEQPsKKsYwABE+4qLi4ggoA5oYARc8Kcxfj/z94rpf3Q1d010zUDGh6vz2jxt7Z5PfwhYLZLR6fZ1R2UUE+gmYUjEu2Fvn4YGJRYWGdDlj0MIxGRWBxGQ5YDiTE3Can0eGYim1OVk26+QFGm1Pi0zMCszCk5L0UWFnXHUlazsax5xSZe0iIjq7AmZSUrUoXk+gZsbm2Xd2rplLJuPgHO7t4+HByKHB3DiX3q6NWzc4nW4eIS6ldyfdO8/vbuXqyHx6fnF3k1G/+Pvr37DP9H7vPr+9f+APCSG/YZmgLjAAAAAElFTkSuQmCC) center no-repeat;
  }
  .user-order-table__header {
    display: none;
  }
  .user-order__body {
    padding-top: 18px;
    padding-bottom: 19px;
  }
  .user-order-info {
    display: none;
  }
  .user-order-table__row {
    padding: 13px 0 0 0;
    border-radius: 0;
    flex-direction: column;
    align-items: flex-start;
  }
  .user-order-table__row-col,
  .user-order-table__header-col {
    text-align: left;
    flex-basis: 100%;
    max-width: 100%;
    margin-bottom: 10px;
  }
  .user-order-table__row:nth-child(odd) {
    background-color: #f0f1ec;
    position: relative;
  }
  .user-order-table__row:nth-child(odd)::before,
  .user-order-table__row:nth-child(odd)::after {
    content: '';
    position: absolute;
    top: 0;
    display: block;
    height: 100%;
    width: 100vw;
    background-color: #f0f1ec;
  }
  .user-order-table__row:nth-child(odd)::before {
    right: 100%;
  }
  .user-order-table__row:nth-child(odd)::after {
    left: 100%;
  }
  .user-order-table__row-col-key {
    display: inline-block;
    color: #343434;
    font-size: 15px;
    font-weight: 500;
  }
  .message-alert {
    width: 95%;
  }
}
@media (max-width: 767px) {
  .container {
    max-width: none;
  }
}
@media (max-width: 700px) {
  .auth-block {
    max-width: none;
    margin-top: 32px;
    border: none;
  }
  .auth-header__wrap {
    margin-bottom: 16px;
    margin-top: 0;
  }
  .auth-form__wrap {
    padding: 0;
  }
  .auth-form-block {
    align-items: flex-start;
    flex-direction: column;
  }
  .auth-form-block__label {
    margin-bottom: 14px;
  }
  .auth-form-block__input {
    max-width: none;
  }
  .auth-form-radios {
    padding: 16px 0 16px 0;
    flex-direction: row;
    align-items: center;
  }
  .auth-form-radios .auth-form-block__label {
    margin-bottom: 0;
  }
  .auth-form-country__right,
  .auth-form-radios__right {
    max-width: none;
  }
  .auth-form-personal {
    max-width: none;
    flex-direction: column;
    align-items: flex-start;
  }
  .auth-form-personal .radio {
    margin-bottom: 4px;
  }
  .auth-form-personal__link {
    padding-left: 32px;
  }
  .login .auth-form__wrap {
    padding: 0;
  }
  .login .auth-form-register__btn-wrap {
    max-width: none;
  }
  .login .auth-form-register__btn-wrap {
    max-width: none;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .login .auth-form-register__btn:nth-child(1) {
    padding: 12px 36px 13px 36px;
  }
  .login .auth-form-register__btn:nth-child(2) {
    padding: 14px 25px 14px 25px;
  }
  .login .auth-form-checkbox .radio {
    margin-right: 0;
  }
  .login .auth-form-checkbox .radio__label {
    align-items: flex-start;
  }
  .login .auth-form-checkbox .radio__check {
    margin-top: -3px;
  }
}

/*.App {*/
/*  text-align: center;*/
/*}*/

/*.App-logo {*/
/*  !*height: 5vmin;*!*/
/*  max-width: 114px;*/
/*  width: 100%;*/
/*  !*pointer-events: none;*!*/
/*}*/

/*!*@media (prefers-reduced-motion: no-preference) {*/
/*  .App-logo {*/
/*    animation: App-logo-spin infinite 20s linear;*/
/*  }*/
/*}*!*/

/*.App-header {*/
/*  background-color: #282c34;*/
/*  min-height: 100vh;*/
/*  display: flex;*/
/*  flex-direction: column;*/
/*  align-items: center;*/
/*  justify-content: center;*/
/*  font-size: calc(10px + 2vmin);*/
/*  color: white;*/
/*}*/

/*.App-link {*/
/*  color: #61dafb;*/
/*}*/

/*@keyframes App-logo-spin {*/
/*  from {*/
/*    transform: rotate(0deg);*/
/*  }*/
/*  to {*/
/*    transform: rotate(360deg);*/
/*  }*/
/*}*/



/*!*FORM HEADER*!*/
/*  .form-header {*/
/*    position: relative;*/
/*    display: flex;*/
/*    flex-direction: row;*/
/*    justify-content: space-around;*/
/*  }*/
/*  .form-header-line {*/
/*    position: absolute;*/
/*    top: 50%;*/
/*    transform: translateY( -50% );*/
/*    width: 100%;*/
/*    height: 1px;*/
/*    z-index: 1;*/
/*    background-color: gray;*/
/*  }*/
/*  .form-header-icon {*/
/*    font-size: 30px;*/
/*    color: #6d6969;*/
/*  }*/
/*  .form-header-icon-wrapper {*/
/*    display: inline-block;*/
/*    background: #fff;*/
/*    text-align: center;*/
/*    width: 40px;*/
/*    border-radius: 50%;*/
/*    z-index: 3;*/
/*    position: relative;*/
/*  }*/
/*  .form-header-icon-wrapper.active > .form-header-icon {*/
/*    color: #005cbf;*/
/*  }*/
/*  .form-header .progress-bar , .form-header .progress {*/
/*    height: 10px;*/
/*    transition: all .5s;*/
/*  }*/
/*  .form-header .progress-bar {*/
/*    width: 0;*/
/*  }*/
/*  .loader {*/
/*    width: 40px;*/
/*    height: 40px;*/
/*    display: block;*/
/*    margin: auto;*/
/*  }*/
/*  .loader-hidden {*/
/*    width: 0;*/
/*  }*/
/*!*FORM HEADER*!*/


/*!*STEP 2*!*/

/*!*ADDRESSES*!*/

/*.address-inner-wrapper {*/
/*  height: 100%;*/
/*  border: 1px solid rgba( 0,0,0,0.5);*/
/*  margin: 1px;*/
/*  padding: 5px;*/
/*  cursor: pointer;*/
/*}*/
/*.address-inner-wrapper.selected {*/
/*  border: 2px solid rgba( 0,0,0,1);*/
/*  margin: 0;*/
/*}*/
/*.address-inner-wrapper.min-height {*/
/*  min-height: 130px;*/
/*}*/
/*.address-inner-wrapper.min-height-alt {*/
/*	min-height: 50px;*/
/*}*/

/*.address-wrapper p {*/
/*  margin-bottom: 5px;*/
/*  font-size: 14px;*/
/*  font-weight: 500;*/
/*}*/

/*.add-new-icon {*/
/*  font-size: 40px;*/
/*}*/

/*.add-new-icon-wrapper {*/
/*  margin-top: 50%;*/
/*  transform: translateY(-50%);*/
/*}*/
/*!*ADDRESSES*!*/

/*!*STEP 2*!*/

/*!*STEP 3*!*/

/*.image_thumb {*/
/*  width: 30%;*/
/*  max-width: 300px;*/
/*}*/

/*!*STEP 3*!*/
@media screen and (min-width: 705px) and (max-width:1347px) {
   .order-product__header .order-product__desc-img {
        display: block!important;
    height: 60px;
    width: 60px;
    position: absolute;
    }
}
.voc_dmoc_stepthree {
    display: block;
    font-size: 14px!important;
}
.voc_dmoc_stepthree_mobile {
    display: block;
    font-size: 13px!important;

}
.order-cart-result-bottom-custom {
    justify-content: center!important;
    padding-right: 0!important;
}
.bold {
    font-weight:700 ;
}
.checkbox-stepthree {
    max-width: none;
    max-width: initial;
    margin-bottom: 15px;
}
.radio-custom {
    margin-right: 9px!important;
}
.radio-custom .radio__label{
    margin-bottom: 0!important;
}
.radio-custom~ a{
    font-size: 16px;
}
.order-cart-title-creating-order {
    text-align: center;
    font-size: 30px;
    margin-top: 10px;
    margin-bottom: 50px;
}
.order-cart-result-bottom__cost-header {
  font-size: 15px;
}

@media (min-width: 1366px) {
  .order-product__body{
       display: none!important;
  }
.step3shown .order-product__desc-img{
     display: flex!important;
  width: 128px;
}
}
@media (max-width: 1366px) {
.step3shown .order-product__body{
     display: block!important;
}.order-product__desc-img{
     display: none!important;
}
}
  .loader {
    width: 40px;
    height: 40px;
    display: block;
    margin: 1%;
    margin-left: auto;
    margin-right: auto;
  }
  .loader-hidden {
    width: 0;
  }


  .hidden {
    display: none;
  }
  .shown {
    display: block;
  }


  .catalog-wrapper {
    display: flex;
     flex-wrap: wrap;
  }

@media screen and (max-width: 8700px) {
    .catalog-item {
        width: 20%;
        margin: 20px;
        height: 420px;
        overflow: hidden;
        object-fit: contain;

    }
}
@media screen and (max-width: 1700px) {

  .catalog-item {
    width: 40%;
    margin: 20px;
      height: 420px;
      overflow: hidden;
      object-fit: contain;

  }
}
@media screen and (max-width: 700px) {

  .catalog-item {
    width: 100%;
    margin: 20px;
      height: 420px;
      overflow: hidden;
      object-fit: contain;

  }
}
.user-story-header {
  margin-top: 20px;
  margin-bottom: 20px;
}
  .catalog-image {
    height: 300px;
    object-fit: contain;
  }
.catalog-text {
  margin-top: 20px;
  display: block;
    color:#707070;
}
.catalog-button {
    border-radius:10px;
    border: 2px solid #296ebb;
    color: #296ebb;
    padding: 15px 60px;
  margin-top: 20px;
    font-weight: bold;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAACmUlEQVRYhcXX36uNWRgH8M/+YRxqTLmUwoULTcaFTIzixgWuuJIfRUluNEypmQukTP4C4UR+lBJFFPlRnEy5UGZSLsaNJklMM2mYc+Lg7D0Xa732a9vvu9/37H2ab63eX89a67ue9azv87wV3w9twn58hRoqHVrVp6h0uH+LF/gJlxVEHUdwB9fQyLAbKzDWRizDJWzB6aIEpuICThbpkINvMA2/4BQm4XgRAmPRuFcMCB7ciSaOCVs62I0A2a4vg0qcEHbhXxwWFpjpiXrWh3HgAxbgtuCBphC8x/BbbJ+hPbp7wcXYmvG5iZvxfm5Wp3564EZsaUzHX3nzVAWmRY7ZeDBZyyOZBHQz6gFjWrHwvxBoCu7vSmCiMIxbeJxlkATHRHngDVbkGZQ9BcuwQZDvJHElqHSwrwgiV+lg+wQHyxCYj+t4jn8E4emkoEW9uQJflyGwHF9gYSTQK7bhUJkgbGBUf/JGgndlPNBsm3wmdggp+JGQcNZiEUbi8x9YiVWCKA3jAF6JcVE2CNP7uxQ/4j62Y46wr7OETLgZ32Ir1uDXSGBKJADVsjrQHskfhGpqGDOEVR7GbCFetuM1XuIZrghl20dUU4MVQTVlm6jcOgxhn1ZR8l6Q4cTDdaHm/LJ9wLJbkD7PA/G6SGtVFWHV6yPZoziBBzIEqZ7q2A1JYklwD3vxd+rdz1isVQ09FYKxJgNlCLQLzKM4YRqDPq8Bz+UNmuxpJsMUarGNFLAtgneolYmBh0Jkn9cfJfwOD8oQuCv8fOyWU+N1QFPnLf4dP5Q9BWdj6xuqbdd+YyquYkkegZogHBOBOlZjXp7BaDRqCGe9YfwVUvpvuibIM/yZR2AX9ghKlR4gTxuyvqVlmuDZM4JUd8R/CWOKv5hfddsAAAAASUVORK5CYII=);
    background-repeat: no-repeat;
    background-size: 35px;
    background-position: 10px center;
    position: relative;
    top: 40px;
}

  .text-center {
    text-align: center;
  }
.modal-terms iframe {
  height: 100%;
  width: 100%;
}
.modal-terms .modal-contacts__phones,.modal-terms .modal-contacts__body {
  height:100%;
}
.modal-terms {
  max-width: 90%;
    width: 2000px;
    height: 98vh;
    max-height: 98%;
}
.modal-contacts__close,
.modal-contacts__close:active,
.modal-contacts__close:focus,
.modal-contacts__close:hover
{
  border: none;
  outline: none;
}


/*HOME*/
.products-submenu {
  display: block!important;
}
.products-submenu__link:hover {
  cursor: pointer;
}
.products-submenu__link.active {
  color:black;
  font-weight: 600;
}
/*HOME*/


/*FOOTER*/
.layout {
  overflow: visible;
  overflow: initial;
  overflow-x: hidden;
}
.products-footer {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
}
/*FOOTER*/


/*MOBILE-HOME*/
.products-menu-mobile {
  display: block;
}
.products-submenu-mobile__link.active {
  font-weight: bold;
}
@media (max-width: 1365px) {
.products-block .products-block-tabs-content__pane-content {
  display: block;
}
}



.each-slide > div {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  height: 350px;
}

.each-slide span {
  padding: 20px;
  font-size: 20px;
  background: #efefef;
  text-align: center;
}


/*MOBILE-HOME*/

/*AUTH*/
.auth-form-register__btn-wrap {
  max-width: 434px;
}
@media (max-width: 700px) {
.auth-form-register__btn {
  margin-top: 5px;
  margin-bottom: 5px;
}
.login .auth-form-register__btn-wrap {
  justify-content: center;
}}
.auth-form-country__right img {
  width: 35px;
  height: 22px;
}
.auth-form-block__input[type=tel] {
  font-size: 20px;
  color: gray
}
/*AUTH*/

/*STEPS*/
.order-stages__block.active img {
    -webkit-filter: brightness(5);
            filter: brightness(5);
}
.order-stages__block img.dumb-icon {
    width: 44px;
    -webkit-filter: brightness(0.5);
            filter: brightness(0.5);
}
.input-block__field.is-invalid {
  border: 1px solid #dc3545;
  box-shadow: 0 0 0 0.2rem rgb(220 53 69 / 25%);
}
.order-action__comment {
  padding: 15px;
  font-size: 18px;
}
.disabled_input {
    background: transparent;
    border: none;
}
@media (max-width: 1366px) {
.order-delivery__button-wrap,.order-cart-result-center * {
    text-align: center!important;
}
    .order-cart-result-bottom__price-delivery ,
    .order-cart-result-top__cost,
    .order-cart-result-center__cost ,
    .order-cart-result-center__cost span,
    .order-cart-result-bottom__price-delivery  span,
    .order-cart-result-top__cost span {
       font-size: 22px!important;
    }
    .order-cart-result-center {
        padding: 0;
    }
}
@media (max-width: 500px) {


.order-cart-result-bottom__delivery {
    width: 100%;
}
    .order-info-section__header,
    .center-xs,
    label.order-action__label {
        text-align: center;
    }
    .order-cart-result-bottom__delivery-caption,
    .order-cart-result-bottom__delivery-right,
    .order-cart-result-bottom__price-delivery,
    .order-cart-result-center,
    .order-cart-result-center__cost,
    .order-cart-result__cost,
    .order-info-blocks,
    .order-info-block,
    .order-delivery__radios-wrap
    {
    text-align: center!important;
        margin-right: 0;
    }
    .order-cart-result-bottom__price-delivery ,
    .order-cart-result-top__cost,
    .order-cart-result-center__cost ,
    .order-cart-result-center__cost span,
    .order-cart-result-bottom__price-delivery  span,
    .order-cart-result-top__cost span {
       font-size: 16px!important;
    }
    .order-cart-result-center {
        padding: 0;
    }
}
/*STEPS*/


/*ORDERS*/
.user-order__id {
    flex-basis: 35%;
    max-width: 100%;
    text-align: center;
}
.user-order__date {
    flex-basis: 30%;
    max-width: 100%;
    text-align: center;
}
.user-order__name {
    flex-basis: 20%;
    max-width: 100%;
    text-align: center;
}
.user-order__state {
    flex-basis: 20%;
    max-width: 100%;
    text-align: center;
}
.user-order__more {
    max-width: 100%;
    flex-basis: 10%;
    text-align: center;
}
.user-orders-header__id {
    flex-basis: 35%;
    max-width: 100%;
    text-align: center;

}
.user-orders-header__date {
    flex-basis: 30%;
    max-width: 100%;
    text-align: center;

}
.user-orders-header__name {
    flex-basis: 20%;
    max-width: 100%;
    text-align: center;

}
.user-orders-header__state {
    flex-basis: 20%;
    max-width: 100%;
    text-align: center;
}
.user-orders-header__more {
    flex-basis: 10%;
    max-width: 100%;
    text-align: center;
}

@media (max-width: 1366px) {
    .user-order__id {
        flex-basis: 15%;
        font-size:15px;
        text-align: center;
        width: 100%;
        flex: 1 1;
        flex-basis: 100%;
    }
}
@media (min-width: 200px) and (max-width: 500px) {
    .user-order__id,
    .user-order__name,
    .user-order__date,
    .user-order__state,
    .user-order__more {
        flex-basis: 15%;
        font-size:15px;
        text-align: center;
        width: 100%;
        flex: 1 1;
        flex-basis: 100%;
    }
    .user-order__name {
        margin-bottom: 0;
    }
    .user-order__state {
        margin-bottom: 20px;
    }
}
/*ORDERS*/

/*COMMON*/
.my-4 {
  margin-top: 15px;
  margin-bottom: 15px;
}
.profileAddressesDropshippingLabel {
  font-weight: bold;
  font-size: 15px;
  margin-right: 7px;
}
.profileAddressesDropshippingWrapper {
    display: flex;
    align-items: center;
}
.text-gray {
    color:#707070;
}
.contacts-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    align-items: center;
}
.contact-wrapper {
    margin:15px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.contact-img {
    height: 120px;
    border-radius: 50%;
}
.contact-div {
    margin-left: 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
.contact-div p,
.contact-div a {
    margin: 5px;
    font-size: 17px;
    align-self: flex-start;
}
.contact-name,
.contact-phone {
    font-weight: bold;
}
.contact-position {
    color: #6a6a6a;
}
@media screen and (max-width: 400px) {
    .contacts-wrapper {
        flex-direction: column!important;
        justify-content: center!important;
        align-items: center!important;
    }
    .contact-div p,
    .contact-div a {
        font-size: 15px;
    }
    .contact-img {
        height: 70px;
    }
.contact-div {
    margin-left: 10px;
}
}
.mt-6 {
  margin-top: 4rem!important;
}
.my-5 {
  margin-top: 25px;
  margin-bottom: 25px;
}


.pointer {
  cursor: pointer;
}
.contacts ,.basket{
  right:-28px;
}

.btn-danger {
  border: 1px solid #dc3545;
    background-color: #dc3545;
    transition: all 0.25s;
}
.text-center {
  text-align: center!important;
}
.btn-block {
  display: block;
  width: 100%;
}
.btn-danger:hover {
    color: #dc3545;
    background-color: #ffffff;
}
.products-block__price,
.products-block__price span {
  font-size: 17px!important;
}
.user-story-order {
  margin-bottom: 3rem;
}

 .message-alert__descr {
  font-size: 20px;
     line-height: 22px!important;
}
.message-alert-success .message-alert__descr {
  font-size: 30px;
    line-height: 35px!important;
}
.message-alert__close {
      width: 17px;
    height: 17px;
    background-size: contain;
}
.mt-2 {
    margin-top:2rem;
}
.mt-1 {
    margin-top:1rem;
}
.modal-mask {
    position: fixed;
    top: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    left: 0;
    right: 0;
    background: rgba(0,0,0,0.6);
    max-width: 100%;
    z-index: 1500;
}
.message-alert {
  z-index: 1800;
}
@font-face { font-family: Josefin Sans;
			 src: url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;700&display=swap'); }


body , * {
	font-family: 'Montserrat', sans-serif;
}

.header .navigation__link {
    font-size: 14px;
}
.auth-form-register__btn-wrap {
    max-width: 481px;
}
.select_stat {
    min-width: 100%;
    width: 100%;
    border: 1px solid transparent;
    border-radius: 13px;
}
.select_stat.is-invalid {
    border: 1px solid #dc3545;
    box-shadow: 0 0 0 0.2rem rgb(220 53 69 / 25%);
    border-radius: 13px;
}
.select_stat .select__curr{
    width: 100%;
    padding: 7px 20px;
    border-radius: 10px;
    font-size: 15px;
    line-height: 37px;
    border: 1px solid #bebebe;
    box-shadow: none;
}

.product-availability-info-container {
    font-size: 12px;
    margin-bottom: 10px;
}
.product-price-info-container {
    font-size: 12px;
    margin-top: 10px;
}
button.disabled {
    background-color: #ededed;
    color: #0908086e;
    border-color: #c2c2c2;;
}
.order-address-button{
    margin: auto;
    display: block;
    padding-left: 68px;
    padding-right: 68px;
}
/*COMMON*/


/*GALLERY*/
.gallery-mask {
    display: none;
    position: fixed;
    top: 0;
    bottom: 0;
    width: 100%;
    left: 0;
    right: 0;
    background: rgba(0,0,0,0.4);
    z-index: 9999;
}
.gallery-close-icon {
    cursor: pointer;
    position: fixed;
    top: 0;
    right: 0;
    margin-right: 5%;
    margin-top: 4%;
    height: 40px;
}
.gallery-prev-icon,
.gallery-next-icon {
    cursor: pointer;
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    margin-right: 3%;
    height: 50px;
}
.gallery-prev-icon {
    left: 0;
    margin-left: 3%;
}
.gallery-img-wrapper {
    width: 100%;
    display: flex;
    height: 100%;
    align-self: center;
    padding-top: 100px;
    padding-bottom: 100px;
    /*background-image: url(https://dealer.dashandel.cz/images/a72deb5d-a16e-4f57-b9fc-ce3a7d261663.jpg);*/
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    /*margin-left: 5%;*/
    /*margin-right: 5%;*/
    justify-content: center;
    align-items: center;
        overflow: hidden;
    /*margin-top: 10%;*/
    /*margin-bottom: 10%;*/
}
.gallery-img-wrapper img {
    width: 79%;
    height: 80%;
    position: fixed;
    object-fit: contain;
    transform: translateY(5800px);
    transition: all .2s ease;
}
.gallery-img-wrapper img.active {
    transition:all .2s;
    transform: translateY(0px);
}
@media screen and (max-width: 500px) {

.gallery-prev-icon,
.gallery-next-icon {
    height: 30px;
    margin-right: 2%;
}
.gallery-prev-icon {
    margin-left: 2%;
}
.gallery-close-icon {
    height: 25px;
}
}
.products-block-card__img img,
.products-block-card__img picture,
[data-gallery] {
    cursor: pointer;
}
/*GALLERY*/

